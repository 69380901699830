import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { Table, Popover, TablePaginationConfig } from 'antd';
import { DONORS_MAX_RESULTS } from 'src/slices/donor';
import { getDonorsForContributor } from 'src/slices/donor';
import { fetchAllContributors } from '../slices/contributor';
import { Donor } from '../slices/donor';
import { contributorsSelector } from '../store';

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    padding: '1vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  button: {
    display: 'block',
    margin: 'auto',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  descriptionPopup: {
    maxWidth: '25vw',
    maxHeight: '50vh',
    overflow: 'auto',
  },
  descriptionText: {
    cursor: 'default',
  },
  name: {
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '12.5vw',
    fontSize: '0.875vw',
  },
  select: {
    display: 'flex',
    margin: '0.625vw 0 auto',
    width: '18.75vw',
    '& .ant-select-selector, .ant-select-selection-search-input, .ant-select-selection-placeholder':
      {
        height: '2vw !important',
        lineHeight: '2vw !important',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
});

export const AdminNeedToValidate: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { donors, searchResult, offset } = useAppSelector(
    ({ donor: { donors, searchResult, offset } }) => ({
      donors,
      searchResult,
      offset,
    }),
  );
  const contributors = useAppSelector(contributorsSelector.selectAll);

  const args = {
    limit: DONORS_MAX_RESULTS,
    offset: 0,
    statusFilter: 'toValidate',
  };
  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: '_id',
      fixed: 'left' as const,
      render: function renderName(name: string, record: Donor) {
        return (
          <Popover
            content={
              <div className={classes.descriptionPopup}>
                <div className={classes.descriptionText}>
                  {record.description}
                </div>
              </div>
            }
          >
            <div
              className={classes.name}
              onClick={() => history.push(`/donor/${record._id}`)}
            >
              {name}
            </div>
          </Popover>
        );
      },
    },
    {
      title: 'Auteur',
      dataIndex: 'authorName',
      key: 'authorName',
    },
    {
      title: 'Priorité',
      dataIndex: 'priority',
      key: 'priority',
    },
  ];
  useEffect(() => {
    dispatch(fetchAllContributors());
    dispatch(getDonorsForContributor(args));
  }, []);

  const fetchDonors = (page: number) => {
    dispatch(
      getDonorsForContributor({
        offset: (page - 1) * DONORS_MAX_RESULTS,
        statusFilter: 'toValidate',
        limit: DONORS_MAX_RESULTS,
      }),
    );
  };

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 0;
    fetchDonors(newSearchPage);
  };

  const donorsList = useMemo<(Donor & { authorName?: string })[]>(
    () =>
      donors.allIds.map((donorId) => {
        const donor = donors.byId[donorId];
        const author = contributors.find(
          (contributor) => contributor._id === donor.author,
        );

        return {
          ...donor,
          authorName: `${author?.firstName ?? ''} ${author?.lastName ?? ''}`,
        };
      }),
    [donors],
  );

  return (
    <>
      <Table
        className={classes.table}
        dataSource={donorsList}
        columns={columns}
        pagination={{
          total: searchResult?.resultsCount,
          current: offset / DONORS_MAX_RESULTS + 1,
          pageSize: DONORS_MAX_RESULTS,
          showSizeChanger: false,
        }}
        onChange={onTableChange}
        rowKey="_id"
      />
    </>
  );
};
