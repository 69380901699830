import React, { useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Avatar, Typography, Modal, Menu, Popover, Dropdown } from 'antd';
import { createUseStyles } from 'react-jss';
import '../../index.css';
import { useHistory } from 'react-router-dom';
import ReadMore from 'src/components/ReadMore';
import { Classification } from 'src/slices/classification';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { TagList } from '../../components/TagList';
import {
  FinancingNeed,
  invalidateFinancingNeeds,
  Offer,
  removeFinancingNeed,
} from 'src/slices/financingNeed';
import { theme } from '../../constants/theme';
import { useAppDispatch } from '../../hooks/store';
import { User } from '../../slices/auth';

const { Title, Text, Link } = Typography;

const useStyles = createUseStyles({
  title: {
    width: '60%',
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.6875vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
  },
  firstLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  moreOutlined: {
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    cursor: 'default',
  },
  titleInformations: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    // alignSelf: 'center',
    lineHeight: '1 !important',
    marginBottom: '4.76% !important',
  },
  fiche: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1vw !important',
    lineHeight: '1 !important',
    marginBottom: '1% !important',
    color: '#5050F9 !important',
  },
  subtitle: {
    marginBottom: '3.62%',
    color: theme.palette.secondary,
    fontWeight: 'bold',
    fontSize: 'calc(5px + 0.6vw)',
    lineHeight: 1,
  },
  leftColumn: {
    width: '53.59%',
    display: 'flex',
    flexDirection: 'column',
    padding: '6.13% 4.98% 8.42%',
    background: 'white',
    borderRadius: '0.3125vw',
  },
  titleTagsContainer: {
    position: 'relative',
    width: 'auto',
    height: 'auto',
    display: 'flex',
    marginBottom: '7.14%',
  },
  tag: {
    marginTop: '4.76%',
    '& .ant-tag': {
      margin: '0px 1.55% 2.38% 0px',
      borderColor: '#ececf8',
      backgroundColor: '#ececf8',
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: 'calc(5px + 0.4vw)',
      lineHeight: 1.5,
    },
  },
  logoImage: {
    width: '17%',
    maxHeight: 'calc(100% + 1.11vh)',
    position: 'relative',
    top: '-1.11vh',
    right: 0,
  },
  editButton: {
    margin: '0 1.87% 0 0',
    fontSize: '0.875vw',
    height: '67.61%',
    padding: '0.25vw 0.9375vw',
  },
  displayLineBreak: {
    whiteSpace: 'pre-line',
    marginBottom: '3.716%',
    fontSize: '0.75vw',
    ['@media screen and (max-width: 1200px)']: {
      fontSize: '9px',
    },
    textJustify: 'auto',
    textAlign: 'justify',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  link: {
    marginRight: '2%',
    width: 'fit-content',
  },
  linkIcon: {
    color: theme.palette.primary,
    margin: '0 0.3336vw 0 0',
    fontSize: '1.0625vw',
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '4.76%',
  },
});

interface Props {
  classification: Classification;
  projectId: string;
  project: FinancingNeed;
  user: User;
}

const ProjectPageLeftColumn: React.VFC<Props> = ({
  classification,
  projectId,
  project,
  user,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  if (!classification || !project) return <FullscreenSpin />;

  const { tags } = classification;

  /* Remove project */
  const handleRemoveFinancingNeed = () => {
    dispatch(removeFinancingNeed({ financingNeedId: projectId }));

    // TODO: invalidate financing needs
    dispatch(invalidateFinancingNeeds());
    history.goBack();
  };

  const renderConfirmDelete = () => (
    <Modal
      onOk={handleRemoveFinancingNeed}
      okText={'Supprimer définitivement'}
      cancelText={'Annuler'}
      visible={showConfirmDelete}
      onCancel={() => setShowConfirmDelete(false)}
      okButtonProps={{
        danger: true,
      }}
    >
      <Text>{'Ètes-vous sûrs de vouloir supprimer ce projet ?'}</Text>
    </Modal>
  );

  /* Render */
  const renderIconLink = (Icon: React.ComponentType<any>, url: string) => (
    <Link className={classes.link} href={url} target="_blank">
      <Icon className={classes.linkIcon} />
    </Link>
  );

  const menuMoreOutlined = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push(`/financing-need/${projectId}/edit`);
        }}
        icon={<EditOutlined style={{ color: 'blue' }} />}
      >
        {'Modifier'}
      </Menu.Item>

      {project.offers &&
      !project.offers.find((o) => o.offer !== Offer.visibility) ? (
        <Menu.Item
          onClick={() => {
            setShowConfirmDelete(true);
          }}
          icon={<DeleteOutlined style={{ color: 'red' }} />}
        >
          {'Supprimer'}
        </Menu.Item>
      ) : (
        <Menu.Item disabled icon={<DeleteOutlined style={{ color: 'red' }} />}>
          <Popover
            placement={'right'}
            content={
              <>
                Vous ne pouvez pas supprimer un projet ayant une offre payante !
              </>
            }
          >
            {'Supprimer'}
          </Popover>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className={classes.leftColumn}>
      <Title className={classes.fiche}>Fiche projet</Title>
      <div className={classes.titleTagsContainer}>
        <div style={{ marginBottom: 'auto', marginTop: 'auto', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={classes.firstLine}>
              <Title className={classes.title} style={{ maxWidth: '80%' }}>
                {project.name}
              </Title>
              {project.logoUrl && (
                <img
                  src={project.logoUrl}
                  className={classes.logoImage}
                  alt="logo"
                />
              )}
              {(project.adminNgos.findIndex((ngoId) => {
                return ngoId === user.ngoId;
              }) !== -1 ||
                user.role === 'admin') && (
                <Dropdown
                  overlay={menuMoreOutlined}
                  placement={'bottomCenter'}
                  trigger={['click']}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <MoreOutlined
                      style={{ color: '#5050f9' }}
                      className={classes.moreOutlined}
                    />
                  </a>
                </Dropdown>
              )}
            </div>
            {project.websiteUrl && (
              <div className={classes.iconsContainer}>
                {renderIconLink(LinkOutlined, project.websiteUrl)}
              </div>
            )}
            {(project.adminNgos.findIndex((ngoId) => ngoId === user.ngoId) !==
              -1 ||
              user.role === 'admin') && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              ></div>
            )}
          </div>
          {project.tags && (
            <div className={classes.tag}>
              <TagList
                elementIds={project.tags}
                collection={tags}
                color="default"
                collectionName="tags"
              />
            </div>
          )}
        </div>
      </div>
      {project.bannerImageUrl && (
        <Avatar
          icon={<img src={project.bannerImageUrl} alt="banner" />}
          className={classes.logoImage}
        />
      )}
      {project.description && (
        <>
          <Text className={classes.subtitle}>Description</Text>
          <ReadMore
            description={project.description}
            maximumNumberOfCharacters={1267}
          />
        </>
      )}
      {renderConfirmDelete()}
    </div>
  );
};

export default ProjectPageLeftColumn;
