import { useTranslation } from 'react-i18next';
import { Rule } from 'rc-field-form/lib/interface';

export const useFormRules = () => {
  const { t } = useTranslation();

  return {
    required: (options?: { whitespace?: boolean; type?: string }): Rule => ({
      required: true,
      message: t('forms.rules.required'),
      whitespace: options?.whitespace,
      type: options?.type as 'array',
    }),
    status: (): Rule => ({
      type: 'date',
      validator: (_: any, value: any) => {
        if (value !== 'Entrez votre réponse ici') {
          return Promise.resolve();
        }
        return Promise.reject(new Error('forms.rules.required'));
      },
      message: t('forms.rules.required'),
    }),
    checkClosingDate: (
      openingDate: moment.Moment | null,
      closingDate: moment.Moment | null,
    ): Rule => ({
      validator: () => {
        if (
          openingDate &&
          closingDate &&
          openingDate.toDate() &&
          closingDate.toDate() &&
          closingDate.toDate() < openingDate.toDate()
        ) {
          return Promise.reject(new Error('Vérifiez les dates'));
        }
        return Promise.resolve();
      },
      message: t('Vérifiez les dates'),
    }),
    creationYear: (): Rule => ({
      type: 'number',
      message: "La date selectionnée n'est pas valide",
      pattern: /^\d{1,4}$/,
      max: new Date().getFullYear(),
    }),
    email: (): Rule => ({
      type: 'email',
      message: t('forms.rules.email'),
    }),
    url: (): Rule => ({
      type: 'url',
      message: t('forms.rules.url'),
    }),
    phone: (): Rule => ({
      // pattern: /\d{10}/g,
      pattern: new RegExp(/^[\\+]?[(]?\d{3}[)]?[-\\.]?\d{3}[-\\.]?\d{4,6}/),
      message: t('forms.rules.phone'),
    }),
    number: (): Rule => ({
      type: 'number',
    }),
    min: (value: number): Rule => ({
      min: value,
      message: t('forms.rules.min', { value }),
    }),
    max: (value: number): Rule => ({
      max: value,
      message: t('forms.rules.max', { value }),
    }),
  };
};
