type CreateAddRemoveObjectReturn = {
  add: Record<string, unknown[]>;
  remove: Record<string, unknown[]>;
};

export const createAddRemoveObject = (
  previousArrays: Record<string, unknown[]>,
  currentArrays: Record<string, unknown[]>,
): CreateAddRemoveObjectReturn => {
  const add: Record<string, unknown[]> = {};
  const remove: Record<string, unknown[]> = {};

  Object.keys(currentArrays).forEach((key) => {
    const previousValues = new Set(previousArrays[key] || []);
    const currentValues = new Set(currentArrays[key] || []);

    // Calculate items to add
    const toAdd = Array.from(currentValues).filter(
      (item) => !previousValues.has(item),
    );
    if (toAdd.length > 0) {
      add[key] = toAdd;
    }

    // Calculate items to remove
    const toRemove = Array.from(previousValues).filter(
      (item) => !currentValues.has(item),
    );
    if (toRemove.length > 0) {
      remove[key] = toRemove;
    }
  });

  return { add, remove };
};
