import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { SignUpForm } from '../containers/SignUpForm';
import { SignUpFirstStep } from '../containers/SignUpFirstStep';
import { useAppDispatch } from '../hooks/store';
import { signUp } from '../slices/auth';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  logo: {
    alignSelf: 'center',
  },
  // box: {
  //   position: 'relative',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   gap: '1vh',
  //   '& .ant-col-sm-14': {
  //     maxWidth: '100%',
  //   },
  //   '& .ant-col-sm-10': {
  //     maxWidth: '100%',
  //   },
  // },
});

export const SignUp: React.VFC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [error, setError] = useState<string | undefined>(undefined);
  const [organisationTypeValue, setOrganisationTypeValue] = useState<
    'MECENE' | 'PROJECT_HOLDER' | undefined
  >(undefined);

  const onFinish = async (values: any) => {
    const {
      email,
      password,
      firstName,
      lastName,
      organisationName,
      organisationType,
      phoneNumber,
    } = values;
    if (error) {
      setError(undefined);
    }
    const resultAction = await dispatch(
      signUp({
        email,
        password,
        firstName,
        lastName,
        organisationName,
        organisationType,
        phoneNumber,
      }),
    );
    if (signUp.rejected.match(resultAction)) {
      setError(resultAction.error.message);
    } else {
      //window.location.reload();
    }
  };

  return (
    <div className={classes.container}>
      {/*<div className={classes.box}>*/}
      {organisationTypeValue === undefined ? (
        <SignUpFirstStep setOrganisationTypeValue={setOrganisationTypeValue} />
      ) : (
        <SignUpForm
          organisationTypeValue={organisationTypeValue}
          onFinish={onFinish}
          error={error}
          setError={setError}
          dispatch={dispatch}
        />
      )}
      {/*</div>*/}
    </div>
  );
};
