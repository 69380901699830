import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import { useParams, useRouteMatch } from 'react-router-dom';
import { getUsers } from 'src/slices/auth';
import { getClassification } from 'src/slices/classification';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  addNgo,
  getCallForTender,
  getCallForTenderFromDonorId,
  removeNgo,
} from '../slices/callForTender';
import {
  getFinancingNeeds,
  invalidateFinancingNeeds,
} from '../slices/financingNeed';
import { getNgo } from '../slices/ngo';
import NgoPageLeftColumn from './ngoPage/LeftColumn';
import NgoFirstProject from './ngoPage/NgoFirstProject';
import NgoPageRightColumn from './ngoPage/RightColumn';

const useStyles = createUseStyles({
  container: {
    // minHeight: '100%',
    display: 'inline-flex',
    // width: '100%',
    // padding: '3.98% 3.06% 3.98% 2.91%',
    background:
      'radial-gradient(ellipse at top right, #d2dcfd 0%, #f0f2ff 20%, transparent 50%),\
      radial-gradient(ellipse at top left, #fdfefe 0%, #f4f7ff 20%, transparent 50%),\
      radial-gradient(ellipse at bottom left, #e5ecfe, #f6f9fe 30%, transparent 50%),\
      radial-gradient(ellipse at bottom right, #ffffff, #fbfbfb 5%, #f8fcfd 20%, #f0f2ff 30%, transparent 75%)',
  },
});

interface Props {
  setIsNavBarHidden: (isHidden: boolean) => void;
}

export const Ngo: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const match = useRouteMatch('/ngo/:ngoId/bypass');
  const isBypassPresent = match !== null;
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { ngoId } = useParams<{ ngoId: string }>();
  const { callForTenderId } = parse(search);

  const { classification } = useAppSelector(
    ({ classification: { classification } }) => ({
      classification,
    }),
  );

  const {
    ngo,
    user,
    financingNeedsEntities,
    financingNeedLoading,
    callForTender,
    callForTenders,
  } = useAppSelector(
    ({ ngo: { ngo }, auth, financingNeed, callForTender }) => ({
      ngo,
      user: auth.user,
      financingNeedsEntities: financingNeed.financingNeeds.byId,
      financingNeedLoading: financingNeed.loading,
      callForTender: callForTenderId
        ? callForTender.callForTenders.byId[callForTenderId as string]
        : undefined,
      callForTenders: callForTender.callForTenders,
    }),
  );

  useEffect(() => {
    if (callForTenderId) {
      dispatch(getCallForTender(callForTenderId as string));
    }
  }, [callForTenderId]);

  useEffect(() => {
    if (user?.donorId) {
      dispatch(getCallForTenderFromDonorId(user?.donorId));
    }
  }, [user?.donorId]);

  useEffect(() => {
    dispatch(getNgo(ngoId));
    dispatch(invalidateFinancingNeeds());
    dispatch(getFinancingNeeds(ngoId));
  }, [ngoId]);

  useEffect(() => {
    if (ngo?.admins) {
      dispatch(
        getUsers(/*{
          usersIds: ngo.admins,
        }*/),
      );
    }
  }, [ngo?.admins]);

  useEffect(() => {
    dispatch(getClassification());
  }, []);

  if (!classification || !ngo || !user || financingNeedLoading)
    return <FullscreenSpin />;

  function onAddNgo(ngoId: string, callForTenderId: string) {
    dispatch(addNgo({ callForTenderId, body: { ngoId: ngoId } }));
  }
  function onRemoveNgo(ngoId: string, callForTenderId: string) {
    dispatch(removeNgo({ callForTenderId, body: { ngoId: ngoId } }));
  }

  const renderNgo = () => {
    setIsNavBarHidden(false);
    return (
      <div style={{ width: '81.6vw', display: 'flex', flexDirection: 'row' }}>
        <NgoPageLeftColumn
          ngoId={ngoId}
          classification={classification}
          ngo={ngo}
          user={user}
          projects={Object.values(financingNeedsEntities)}
          callForTender={callForTender}
          callForTenders={Object.values(callForTenders.byId)}
          onAddNgo={onAddNgo}
          onRemoveNgo={onRemoveNgo}
        />
        <NgoPageRightColumn classification={classification} ngo={ngo} />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {Object.values(financingNeedsEntities).length > 0 ||
      isBypassPresent ||
      user?.role === 'admin' ||
      ngoId !== user?.ngoId ? (
        renderNgo()
      ) : (
        <NgoFirstProject
          user={user}
          projects={Object.values(financingNeedsEntities)}
          setIsNavBarHidden={setIsNavBarHidden}
        />
      )}
    </div>
  );
};
