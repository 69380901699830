import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  FinancingNeed,
  getFinancingNeed,
  removeFinancingNeed,
} from 'src/slices/financingNeed';
import { getDealFlow } from 'src/slices/dealFlow';
import { Kanban } from 'src/containers/Kanban';
import { Typography, Modal, Spin } from 'antd';

const { Text } = Typography;

interface Props {
  financingNeed: FinancingNeed;
}

const useStyles = createUseStyles({
  projectKanban: {
    display: 'inline-flex',
    width: '100%',
    padding: '2.8% 3.45%',
  },
  kanbanContainer: {
    flex: '1',
    height: '100%',
  },
  spinContainer: {
    display: 'flex',
    padding: 'auto',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const ProjectKanbanNgos: React.VFC<Props> = ({ financingNeed }) => {
  const classes = useStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { loading } = useAppSelector(({ dealFlow: { loading } }) => ({
    loading,
  }));
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getFinancingNeed(projectId));
  }, [projectId]);

  useEffect(() => {
    if (financingNeed) dispatch(getDealFlow(financingNeed.dealFlow));
  }, [financingNeed]);

  const handleRemoveFinancingNeed = () => {
    dispatch(removeFinancingNeed({ financingNeedId: financingNeed._id }));
    history.goBack();
  };

  const renderConfirmDelete = () => (
    <Modal
      onOk={handleRemoveFinancingNeed}
      okText={'Oui'}
      cancelText={'Annuler'}
      visible={showDeleteModal}
      onCancel={() => setShowDeleteModal(false)}
    >
      <Text>{'Ètes-vous sûr de vouloir supprimer ce projet ?'}</Text>
    </Modal>
  );
  if (loading) {
    return (
      <div className={classes.spinContainer}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={classes.projectKanban}>
      <div className={classes.kanbanContainer}>
        <Kanban />
      </div>
      {renderConfirmDelete()}
    </div>
  );
};
