import { Button, message, Modal, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { fetchCurrentUser } from 'src/slices/auth';
import { theme } from '../constants/theme';
import { apiRequest } from '../helpers/api';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { getFinancingNeeds, Offer } from '../slices/financingNeed';
import { getNgo } from '../slices/ngo';
import { checkHasPaidOffer, isOrganizationPremium } from '../utils/projects';

const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    backgroundImage: `url("../../images/dashboardLogo.png")`,
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  fondations: {
    paddingLeft: '5.59%',
    paddingTop: '4.59%',
    fontWeight: '600',
    fontFamily: "'Baloo 2'",
    fontSize: '2.125vw',
    lineHeight: '1',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '2.3vw !important',
    lineHeight: '1 !important',
    margin: '0 0 4.76% 0 !important',
  },
  cardButtonUnsubscribe: {
    width: '350px',
    height: '100px',
    fontSize: '24px',
    backgroundColor: '#2dc4a8',
    border: '1px solid #2dc4a8',
    color: 'white',
    '&:hover': {
      backgroundColor: 'white',
      border: '1px solid #2dc4a8',
      color: '#2dc4a8',
    },
  },
  expirationDate: {
    color: theme.palette.primary,
    fontSize: '0.6125vw !important',
  },
  expirationDateAlt: {
    color: '#000',
    fontSize: '0.6125vw !important',
  },
  buttonNational: {
    minHeight: '4vw',
    background:
      'linear-gradient(to right, rgba(0, 0, 255, 0.2) 0%, rgba(0, 0, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 33.33%, rgba(255, 255, 255, 0.2) 66.66%, rgba(255, 0, 0, 0.2) 66.66%)',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    color: '#000',
    '&:hover': {
      background:
        'linear-gradient(to right, rgba(0, 0, 255, 0.3) 0%, rgba(0, 0, 255, 0.3) 33.33%, rgba(255, 255, 255, 0.3)' +
        ' 33.33%, rgba(255, 255, 255, 0.3) 66.66%, rgba(255, 0, 0, 0.3) 66.66%)',
      backgroundSize: '100% 100%',
      backgroundRepeat: 'no-repeat',
    },
  },
  content: {
    backgroundColor: 'white',
    width: '80%',
    maxWidth: '1059px',
    padding: '20px',
    borderRadius: '10px',
    border: '1px solid #E5E5E5',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5.59%',
    marginLeft: '5.59%',
    gap: '20px',
  },
  row: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  itemTitle: {
    width: '50%',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#ADAAAA',
  },
  itemValue: {
    width: '50%',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '22px',
    color: 'black',
  },
  unsubscribe: {
    marginTop: '32px',
    color: '#C9C6C5',
    cursor: 'pointer',
    fontWeight: '400',
    alignSelf: 'flex-start',
    fontSize: '14px',
    lineHeight: '20px',
    textDecoration: 'underline',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  modalTitle: {
    fontSize: '18px',
    lineHeight: '22px',
    color: '#000',
    fontWeight: '600',
  },
  modalSubtitle: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#737373',
    fontWeight: '400',
    textAlign: 'center',
  },
  modalButtons: {
    display: 'flex',
    flexDirection: 'column',
    height: '92px',
    justifyContent: 'space-between',
  },
  buttonConfirm: {
    backgroundColor: theme.palette.primary,
    borderRadius: '6px',
    height: '40px',
    border: 'none',
  },
  buttonCancel: {
    backgroundColor: '#F3F6FD',
    margin: '0 !important',
    height: '40px',
    border: 'none',
    color: 'black',
    borderRadius: '6px',
  },
  modal: {
    // center the modal
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    // no scroll bar
    height: '65dvh',
    '& .ant-modal-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    maxWidth: '400px',
    '& .ant-modal-content': {
      borderRadius: '6px',
    },
    '& .ant-modal-body': {
      display: 'none !important',
    },
    '& .ant-modal-footer': {
      borderTop: 'none',
      paddingBottom: '24px',
    },
    '& .ant-modal-header': {
      borderBottom: 'none',
    },
  },
});

interface PremiumItem {
  title: string;
  value: string;
  styleTitle?: React.CSSProperties;
  styleValue?: React.CSSProperties;
}

interface Props {
  setIsNavBarHidden?: (isHidden: boolean) => void;
}

export const PremiumNgo: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);

  const { financingNeeds, loading } = useAppSelector(
    ({ financingNeed: { financingNeeds, loading } }) => ({
      financingNeeds,
      loading,
    }),
  );

  useEffect(() => {
    if (setIsNavBarHidden) {
      setIsNavBarHidden(false);
    }
    fetchCurrentUser();
    dispatch(getFinancingNeeds(user?.ngoId));
  }, []);

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getNgo(user.ngoId));
    }
  }, [user?.ngoId]);

  const cancelSubscription = async () => {
    try {
      await apiRequest(
        'DELETE',
        `/payment/subscriptionDatabaseAccess/${paidFinancingNeed?._id}/${Offer.complete}/ngo`,
        undefined,
        {},
      );
    } catch (error) {
      message.error(
        'Une erreur est survenue. Merci de rafraichir la page pour réessayer',
      );
    }
  };

  const isPremium = useMemo(() => {
    return isOrganizationPremium(Object.values(financingNeeds.byId));
  }, [financingNeeds]);

  const paidFinancingNeed = useMemo(() => {
    return Object.values(financingNeeds.byId).find((fn) =>
      checkHasPaidOffer(fn),
    );
  }, [financingNeeds]);

  useEffect(() => {
    if (
      !loading &&
      Object.values(financingNeeds.byId) &&
      Object.values(financingNeeds.byId).length > 0 &&
      !isPremium
    ) {
      history.push(
        `/subscription/${
          Object.values(financingNeeds.byId)[0]._id
        }/${isPremium}`,
      );
    }
  }, [financingNeeds, loading]);

  const [items, setItems] = useState<PremiumItem[]>([]);

  if (!user || !financingNeeds.byId) {
    return null;
  }

  useEffect(() => {
    if (financingNeeds.byId) {
      const paidFinancingNeed = Object.values(financingNeeds.byId).find((fn) =>
        checkHasPaidOffer(fn),
      );
      if (paidFinancingNeed) {
        setItems([
          {
            title: 'Numéro client',
            value: user._id,
          },
          {
            title: 'Email associé',
            value: user.email ?? '',
          },
          {
            title: "Identifiant de l'association",
            value: user.ngoId ?? '',
          },
          {
            title: 'Votre souscription',
            value: `Offre premium`,
          },
          {
            title: 'Statut de souscription',
            value: 'En cours',
            styleValue: { color: theme.palette.secondary },
          },
          {
            title: "Date d'échéance",
            value: new Date(
              paidFinancingNeed.offers.find((o) => o.offer === Offer.complete)
                ?.expirationDate || '',
            ).toLocaleDateString('fr-FR'),
          },
        ]);
      }
    }
  }, [paidFinancingNeed]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text
          className={classes.fondations}
          style={{ color: theme.palette.primary }}
        >
          Ma souscription
        </Text>
      </div>
      <div className={classes.content}>
        {items.map((item, index) => (
          <div className={classes.row} key={index}>
            <Text className={classes.itemTitle} style={item.styleTitle}>
              {item.title}
            </Text>
            <Text className={classes.itemValue} style={item.styleValue}>
              {item.value}
            </Text>
          </div>
        ))}
        <Text
          className={classes.unsubscribe}
          onClick={() =>
            setShowCancelSubscriptionModal(!showCancelSubscriptionModal)
          }
        >
          Me désinscrire
        </Text>
      </div>
      <Modal
        className={classes.modal}
        maskStyle={{
          backdropFilter: 'blur(6px)',
          backgroundColor: '#0000000F',
        }}
        width={600}
        title={
          <div className={classes.modalHeader}>
            <Text className={classes.modalTitle}>Êtes-vous sûr ?</Text>
            <Text className={classes.modalSubtitle}>
              Êtes-vous sûr de vouloir vous désinscrire de votre offre premium ?
            </Text>
          </div>
        }
        open={showCancelSubscriptionModal}
        onCancel={() => setShowCancelSubscriptionModal(false)}
        footer={
          <div className={classes.modalButtons}>
            <Button
              key="submit"
              type={'primary'}
              onClick={() => {
                cancelSubscription();
                setShowCancelSubscriptionModal(false);
                alert(
                  'Le renouvellement de votre abonnement a bien été annulé.',
                );
              }}
              className={classes.buttonConfirm}
            >
              Je me désinscris
            </Button>
            <Button
              key="back"
              onClick={() => setShowCancelSubscriptionModal(false)}
              type={'primary'}
              className={classes.buttonCancel}
            >
              Annuler
            </Button>
          </div>
        }
      />
    </div>
  );
};
