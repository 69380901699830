import { BellOutlined } from '@ant-design/icons';
import { Empty, Modal, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { DonorCard } from '../components/cards/donorCard';
import { theme } from '../constants/theme';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { Donor } from '../slices/donor';
import { getFollowedDonors, getNgo, removeFollowedDonor } from '../slices/ngo';
const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    backgroundImage: `url("../../images/dashboardLogo.png")`,
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  fondations: {
    paddingLeft: '5.59%',
    paddingTop: '4.59%',
    fontWeight: '600',
    fontFamily: "'Baloo 2'",
    fontSize: '2.125vw',
    lineHeight: '1',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerCards: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    margin: '32px 64px',
    paddingBottom: '4dvh',
  },
  flexWrapper: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: '20px',
    rowGap: '0px',
  },
  flexItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  pagination: {
    paddingBottom: '4dvh',
  },
  link: {
    marginRight: '0.90vw',
    width: 'fit-content',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkIcon: {
    color: theme.palette.primary,
    fontSize: '16px',
    position: 'absolute',
    top: '154px',
    left: '14px',
    transform: 'translateX(-50%)',
    '&:hover': {
      color: 'black',
    },
  },
});

export const FollowedDonors = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [donorId, setDonorId] = useState<string>('');

  // get user from store
  const { user, followedDonors, followedDonorsLoading, ngo } = useAppSelector(
    ({
      auth: { user },
      ngo: { ngo, followedDonors, followedDonorsLoading },
    }) => ({
      user,
      followedDonors,
      followedDonorsLoading,
      ngo,
    }),
  );

  useEffect(() => {
    if (!user) {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getNgo(user.ngoId));
    }
  }, []);

  useEffect(() => {
    dispatch(getFollowedDonors());
  }, []);

  const onModalCancel = () => {
    setIsModalVisible(false);
  };
  const onModalOk = () => {
    dispatch(removeFollowedDonor({ donorId: donorId }));
    setIsModalVisible(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text
          className={classes.fondations}
          style={{ color: theme.palette.primary }}
        >
          Mes abonnements
        </Text>
      </div>
      {followedDonorsLoading === 'succeeded' && followedDonors.length === 0 && (
        <Empty
          description={`Vous n'avez pas encore d'abonnements`}
          style={{ maxWidth: '500px', marginTop: '50px' }}
        />
      )}
      {followedDonorsLoading === 'succeeded' && (
        <div className={classes.containerCards}>
          <div className={classes.flexWrapper}>
            {followedDonors.map((donor: Donor) => (
              <div key={donor._id} className={classes.flexItem}>
                <DonorCard key={donor._id} donor={donor} />
                {ngo &&
                  ngo.followedDonors &&
                  ngo?.followedDonors.includes(donor._id) && (
                    <>
                      {
                        <span
                          className={classes.link}
                          onClick={() => {
                            setDonorId(donor._id);
                            setIsModalVisible(true);
                          }}
                        >
                          <BellOutlined className={classes.linkIcon} />
                        </span>
                      }
                    </>
                  )}
              </div>
            ))}
          </div>
        </div>
      )}
      <Modal
        open={isModalVisible}
        onCancel={onModalCancel}
        onOk={onModalOk}
        okButtonProps={{ danger: true }}
        okText={'Se désabonner'}
        cancelText={'Annuler'}
      >
        <Text>
          Êtes-vous sûr de vouloir vous désabonner de{' '}
          {followedDonors.find((donor) => donor._id === donorId)?.name} ?
        </Text>
      </Modal>
    </div>
  );
};
