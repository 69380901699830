import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { Draggable } from 'react-beautiful-dnd';
import {
  DealFlowCard,
  deleteDealFlowCard,
  updateDealFlowCard,
} from 'src/slices/dealFlowCard';
import { Button, Comment, Form, List, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import TextArea from 'antd/es/input/TextArea';
import { useAppSelector } from 'src/hooks/store';
import closeIcon from '../assets/icons/closeKanbanCard.svg';
import { KanbanNote } from './KanbanNote';
import { useDispatch } from 'react-redux';
import { createDealFlowNote, getDealFlowNotes } from 'src/slices/dealFlowNote';
import Text from 'antd/lib/typography/Text';
import { useHistory } from 'react-router-dom';
import messageIcon from '../assets/icons/messageIcon.svg';

interface Props {
  cardId: string;
  index: number;
  columnId: string;
  projectId: string;
}

const useStyles = createUseStyles({
  cardCover: {
    backgroundColor: '#f2f2fe',
    borderColor: theme.palette.common.white,
    padding: '10px',
    display: 'flex',
    flexDirection: 'column', // Assure une organisation verticale
    justifyContent: 'space-between',
    alignContent: 'center',
    overflow: 'hidden',
    height: '112px',
    marginTop: '10px',
    position: 'relative', // Ajout pour permettre le positionnement absolu des enfants
  },
  notesBadge: {
    backGroundColor: theme.palette.primary,
  },
  modalCard: {
    width: '100%',
  },
  title: {
    fontSize: '18px',
    lineHeight: '22px',
    color: '#000',
    fontWeight: '600',
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    display: '-webkit-box', // Required for limiting text to a specific number of lines
    WebkitLineClamp: 2, // Limits the text to 2 lines
    WebkitBoxOrient: 'vertical', // Required for multi-line ellipsis
    overflow: 'hidden', // Hides overflowing text
    textOverflow: 'ellipsis', // Adds the ellipsis
    maxWidth: '180px',
  },
  cardFooter: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute', // Positionné par rapport à .cardCover
    bottom: '8px',
    left: '10px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#737373',
  },
  messageIcon: {
    width: '12px',
    height: '12px',
    marginRight: '4px',
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: '0.875vw',
  },
  notesList: {
    maxHeight: '84.75%',
    overflow: 'scroll',
  },
  modal: {
    // center the modal
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    height: '65dvh',
    '& .ant-modal-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    maxWidth: '700px',
    '& .ant-modal-content': {
      borderRadius: '6px',
    },
    '& .ant-modal-body': {
      paddingTop: '0',
      paddingBottom: '0',
      // display: 'none !important',
    },
    '& .ant-modal-footer': {
      borderTop: 'none',
      paddingBottom: '24px',
    },
    '& .ant-modal-header': {
      borderBottom: 'none',
    },
  },
  removeDonorModal: {
    maxWidth: '400px',
    // center the modal
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    height: '65dvh',
    '& .ant-modal-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .ant-modal-content': {
      borderRadius: '6px',
    },
    '& .ant-modal-body': {
      paddingTop: '0',
      paddingBottom: '0',
      // display: 'none !important',
    },
    '& .ant-modal-footer': {
      borderTop: 'none',
      paddingBottom: '24px',
    },
    '& .ant-modal-header': {
      borderBottom: 'none',
    },
  },
  removeButton: {
    backgroundColor: '',
  },
  closeIcon: {
    height: '20px',
    cursor: 'pointer',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  modalTitle: {
    fontSize: '18px',
    lineHeight: '22px',
    color: '#000',
    fontWeight: '600',
  },
  modalSubtitle: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#737373',
    fontWeight: '400',
    textAlign: 'center',
  },
  modalButtons: {
    display: 'flex',
    flexDirection: 'column',
    height: '92px',
    justifyContent: 'space-between',
  },
  buttonConfirm: {
    backgroundColor: theme.palette.primary,
    borderRadius: '6px',
    height: '40px',
    border: 'none',
  },
  buttonCancel: {
    backgroundColor: '#F3F6FD',
    margin: '0 !important',
    height: '40px',
    border: 'none',
    color: 'black',
    borderRadius: '6px',
  },
  commentAreaTitle: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
  },
  commentArea: {
    marginTop: '8px',
  },
  commentSection: {
    marginTop: '32px',
  },
  commentHeader: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  commentHeaderNotesList: {
    marginTop: '12px',
    maxHeight: '84.75%',
    overflow: 'scroll',
  },
  buttonGroup: {
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  cancelButton: {
    width: '226px',
    borderRadius: '6px',
    height: '40px',
    background: '#fff',
    border: '1px solid #E5E5E5',
    color: '#000',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  submitButton: {
    width: '226px',
    borderRadius: '6px',
    height: '40px',
    background: '#6366F1',
    border: 'none',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  scrollableList: {
    maxHeight: '300px',
    overflowY: 'auto',
    paddingRight: '8px',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#C0C0C0',
      borderRadius: '3px',
    },
  },
});

const MAX_NOTES = 5;

export const KanbanCard: React.VFC<Props> = ({
  cardId,
  index,
  columnId,
  projectId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { cards, user } = useAppSelector(
    ({ dealFlowCard: { cards }, auth: { user } }) => ({
      cards,
      user,
    }),
  );

  const noteLoading = useAppSelector(({ dealFlowNote: { loading } }) => ({
    loading,
  })).loading;

  const card = useMemo<DealFlowCard>(() => cards[cardId], [cards, cardId]);

  const [showMainModal, setshowMainModal] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<string>('');
  const [editedComment, setEditedComment] = useState<string>('');
  const [displayedNotes, setDisplayedNotes] = useState<string[]>([]);

  useEffect(() => {
    if (showMainModal) {
      dispatch(getDealFlowNotes(cardId));
      if (card) setDisplayedNotes(card.notes.slice(0, MAX_NOTES));
    }
  }, [showMainModal]);

  const onEditComment = (value: string) => {
    setEditedComment(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangeCardName = (cardName: string) => {
    if (cardName !== card.name)
      dispatch(
        updateDealFlowCard({
          body: { name: cardName },
          cardId: card._id,
          dealFlowId: card.dealFlow,
        }),
      );
  };

  const onLoadMoreNotes = () => {
    setDisplayedNotes([
      ...displayedNotes,
      ...card.notes.slice(
        displayedNotes.length,
        displayedNotes.length + MAX_NOTES,
      ),
    ]);
  };

  const onSubmitComment = () => {
    user &&
      dispatch(
        createDealFlowNote({
          body: {
            content: editedComment,
            card: card._id,
            author: user?._id,
          },
          dealFlowId: card.dealFlow,
        }),
      );
    setEditedComment('');
  };

  const onShowMecene = () => {
    window.open(`/donor/${card.donor}?projectId=${projectId}`, '_blank');
  };

  const onShowNgo = () => {
    history.push(`/ngo/${card.ngo}`);
  };

  const onRemoveDonor = () => {
    dispatch(
      deleteDealFlowCard({
        body: {
          columnId: columnId,
          projectId: projectId,
          donorId: card.donor,
        },
        cardId: cardId,
      }),
    );
  };

  const renderCommentEditor = (
    onChange: (value: string) => void,
    onSubmit: () => void,
    value: string,
  ) => (
    <div className={classes.modalCard}>
      <Form className={classes.commentArea}>
        <Form.Item>
          <TextArea
            rows={4}
            onChange={(e) => onChange(e.target.value)}
            value={value}
            placeholder="Écrivez votre commentaire ici"
          />
        </Form.Item>
      </Form>

      {/* Comments Section */}
      <div className={classes.commentSection}>
        {/* Comments Header */}
        <div className={classes.commentHeader}>
          <Typography>Commentaires</Typography>
        </div>

        {/* Comments List */}
        <List
          className={classes.commentHeaderNotesList && classes.scrollableList}
          itemLayout="vertical"
          dataSource={displayedNotes}
          loadMore={renderLoadMoreNotes()}
          renderItem={(noteId) => (
            <li className={classes.scrollableList}>
              <KanbanNote noteId={noteId} />
            </li>
          )}
          loading={noteLoading}
          locale={{ emptyText: 'Pas de commentaire' }}
        />
      </div>

      {/* Buttons */}
      <div className={classes.buttonGroup}>
        <Button
          className={classes.cancelButton}
          onClick={() => setshowMainModal('')}
        >
          Annuler
        </Button>
        <Button
          className={classes.submitButton}
          onClick={onSubmit}
          type="primary"
        >
          Ajouter
        </Button>
      </div>
    </div>
  );

  const renderCardTitle = () => (
    <Text
      className={classes.title}
      // editable={
      //   card.donor
      //     ? false
      //     : {
      //         onChange: (value: string) => {
      //           onChangeCardName(value);
      //         },
      //         icon: <EditOutlined />,
      //       }
      // }
      onClick={() => {
        user?.ngoId ? onShowMecene() : onShowNgo();
      }}
    >
      {card.name}
    </Text>
  );

  useEffect(() => {
    if (
      displayedNotes &&
      card?.notes &&
      displayedNotes.length < card.notes.length &&
      displayedNotes.length < 5
    ) {
      onLoadMoreNotes();
    }
  }, [displayedNotes?.length, card?.notes?.length]);

  const renderLoadMoreNotes = () =>
    displayedNotes.length < card.notes.length ? (
      <div style={{ padding: '8px' }}>
        <Button onClick={onLoadMoreNotes}>Charger plus</Button>
      </div>
    ) : null;

  const renderCard = () => {
    return (
      <div>
        <Comment
          content={renderCommentEditor(
            onEditComment,
            onSubmitComment,
            editedComment,
          )}
        />
      </div>
    );
  };

  if (!card) return <></>;
  return (
    <Draggable draggableId={cardId} index={index}>
      {(cardProvided) => (
        <div
          ref={cardProvided.innerRef}
          {...cardProvided.draggableProps}
          {...cardProvided.dragHandleProps}
        >
          <div
            className={classes.cardCover}
            onClick={() => {
              setshowMainModal(card._id);
            }}
          >
            <div
              style={{
                height: '48px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <div className={classes.cardTitle} style={{ userSelect: 'none' }}>
                {card.name}
              </div>
              <img
                alt={'delete card'}
                src={closeIcon}
                className={classes.closeIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteModal(card._id);
                }}
              />
            </div>
            <div className={classes.cardFooter}>
              <img
                src={messageIcon}
                alt="Message icon"
                className={classes.messageIcon}
              />
              <Typography>{card.notes.length}</Typography>
            </div>
          </div>

          <Modal
            title={renderCardTitle()}
            footer={null}
            className={classes.modal}
            onCancel={() => setshowMainModal('')}
            open={showMainModal == card._id}
            maskStyle={{
              backdropFilter: 'blur(6px)',
              backgroundColor: '#0000000F',
            }}
          >
            {renderCard()}
          </Modal>
          <Modal
            className={classes.removeDonorModal}
            maskStyle={{
              backdropFilter: 'blur(6px)',
              backgroundColor: '#0000000F',
            }}
            title={
              <div className={classes.modalHeader}>
                <Text className={classes.modalTitle}>Êtes-vous sûr ?</Text>
                <Text className={classes.modalSubtitle}>
                  Êtes-vous sûr de vouloir retirer de votre tableau de suivi le
                  mécène : “{card.name}” ?
                </Text>
              </div>
            }
            open={showDeleteModal == card._id}
            onCancel={() => setShowDeleteModal('')}
            footer={
              <div className={classes.modalButtons}>
                <Button
                  key="submit"
                  type={'primary'}
                  onClick={() => {
                    setShowDeleteModal('');
                    onRemoveDonor();
                  }}
                  className={classes.buttonConfirm}
                >
                  Retirer
                </Button>
                <Button
                  key="back"
                  type={'primary'}
                  className={classes.buttonCancel}
                  onClick={() => setShowDeleteModal('')}
                >
                  Annuler
                </Button>
              </div>
            }
          />
        </div>
      )}
    </Draggable>
  );
};
