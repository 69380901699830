import { notification, TablePaginationConfig, Typography, Select } from 'antd';
import { min } from 'lodash';
import { parse, stringify } from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import { useSortedClassification } from 'src/hooks/useSortedClassification';
import {
  FinancingNeed,
  getFinancingNeed,
  getFinancingNeeds,
  resetFinancingNeedEvent,
} from 'src/slices/financingNeed';
import {
  DatabaseSearchBar,
  SearchFiltersParameters,
} from '../components/DatabaseSearchBar';
import { DonorCards } from '../components/DonorCards';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { theme } from '../constants/theme';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  Donor,
  getDonorsWithAlgorithm,
  resetDonorEvent,
  resetSuggestions,
  searchDonorsWithAlgorithmAndGetSuggestions,
} from '../slices/donor';
import { getNgo } from '../slices/ngo';
import { LoggedIntercom } from '../utils/loggedIntercom';
import { isOrganizationPremium } from '../utils/projects';

const MAX_DISPLAYED_PAGES_NUMBER = 10;

const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    backgroundImage: `url("../../images/dashboardLogo.png")`,
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',

    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  fondations: {
    paddingLeft: '5.59%',
    paddingTop: '4.59%',
    fontWeight: '600',
    fontFamily: "'Baloo 2'",
    fontSize: '2.125vw',
    lineHeight: '1',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2.8%',
  },
  button: {
    fontSize: '0.8200vw',
    margin: 'auto',
    width: '33%',
    textAlign: 'center',
  },
  error: {
    fontSize: '1.500vw',
    paddingTop: '17%',
    paddingLeft: '16.8%',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5%',
    marginTop: '1%',
    maxWidth: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '1.5%',
    // justifyContent: 'center',
    '& > *': {
      margin: '5px 0',
    },
  },
  filtersRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  select: {
    position: 'relative',
    marginRight: '28.6vw',
    marginTop: '0.7vw',
    height: '2.5vw !important',
    width: '15vw !important',
    '& .ant-select .ant-select-selector': {
      height: '2.5vw',
      webkitBoxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      boxShadow: '0.3125vw 0.3125vw 0.3125vw 0px rgba(0,0,0,0.1)',
      borderRadius: '0.25vw',
    },
    '& .ant-select.ant-select-single.ant-select-show-arrow': {
      color: theme.palette.secondary,
      height: '2.5vw !important',
      width: '15vw !important',
      '& .ant-select-selector': {
        height: '2.5vw !important',
        borderRadius: '0.25vw',
        '& .ant-select-selection-item': {
          color: 'black',
          fontFamily: "'Baloo 2'",
          fontSize: '0.875vw',
          textAlign: 'center',
          ['@media screen and (min-width: 768px)']: {
            marginTop: '0%',
          },
          ['@media screen and (min-width: 1200px)']: {
            marginTop: '1%',
          },
          ['@media screen and (min-width: 1600px)']: {
            marginTop: '3%',
          },
          ['@media screen and (min-width: 2160px)']: {
            marginTop: '4.5%',
          },
        },
      },
    },
  },
});

export const DatabaseFromProject: React.VFC = () => {
  const DONORS_MAX_RESULTS = 20;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useLocation().search;
  const { projectId } = useParams<{ projectId: string }>();

  const [searchFilters, setSearchFilters] = useState<SearchFiltersParameters>(
    {},
  );
  useEffect(() => {
    console.log('DatabaseFromProject.tsx: searchFilters:', searchFilters);
  }, [searchFilters]);
  const [queryParamsObject, setQueryParamsObject] = useState<
    Record<string, unknown>
  >(parse(query, { arrayFormat: 'bracket' }));

  const {
    donors,
    financingNeeds,
    loading,
    event,
    // addDonorLoading,
    // removeDonorLoading,
    searchResult,
    user,
    offset,
    suggestionsDonors,
    suggestionsLoading,
    classificationsFilter,
    // callForTendersCounts,
  } = useAppSelector(
    ({
      donor: {
        donors,
        loading,
        event,
        suggestionsLoading,
        searchResult,
        suggestionsDonors,
        offset,
        classificationsFilter,
      },
      financingNeed: { financingNeeds, addDonorLoading, removeDonorLoading },
      auth: { user },
      callForTender: { callForTendersCounts },
    }) => ({
      donors,
      classificationsFilter,
      financingNeeds,
      loading,
      event,
      suggestionsLoading,
      addDonorLoading,
      removeDonorLoading,
      searchResult,
      suggestionsDonors,
      user,
      offset,
      callForTendersCounts,
    }),
  );
  const [dropdownFilterVisible, setDropdownFilterVisible] = useState(false);

  const financingNeedEvent = useAppSelector(({ financingNeed: { event } }) => ({
    event,
  })).event;

  const classification = useSortedClassification();

  const isPremium = useMemo(() => {
    return isOrganizationPremium(Object.values(financingNeeds.byId));
  }, [financingNeeds]);

  LoggedIntercom(
    user?.firstName + ' ' + user?.lastName,
    user?.email,
    user?._id,
    'paidNationalOffer-financingNeed',
  );

  const fetchDonors = (
    page = 1,
    algorithm = 'tagsProximity',
    searchFilters: SearchFiltersParameters,
  ) => {
    updateQueryParams({ algorithm, page, ...searchFilters });
    dispatch(
      getDonorsWithAlgorithm({
        projectId,
        algorithm: algorithm,
        searchParameters: {
          ...searchFilters,
          offset: (page - 1) * DONORS_MAX_RESULTS,
          limit: DONORS_MAX_RESULTS,
        },
      }),
    );
  };

  const fetchDonorsWithQueryParams = () => {
    const {
      page,
      algorithm,
      name,
      tags,
      statuses,
      targetPersons,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      donationTypes,
      department,
    } = queryParamsObject;
    const newSearchFilters = {
      name,
      tags,
      statuses,
      targetPersons,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      donationTypes,
      department,
    } as SearchFiltersParameters;

    setSearchFilters(newSearchFilters);

    fetchDonors(
      page ? parseInt(page as string) : undefined,
      algorithm as string,
      newSearchFilters,
    );
  };

  const updateQueryParams = (params: Record<string, unknown>) => {
    const updatedQueryParamsObject = { ...queryParamsObject, ...params };
    history.replace({
      search: stringify(updatedQueryParamsObject, { arrayFormat: 'bracket' }),
    });
    setQueryParamsObject(updatedQueryParamsObject);
  };

  useEffect(() => {
    dispatch(getNgo(user?.ngoId));
  }, [user?.ngoId]);

  useEffect(() => {
    fetchDonorsWithQueryParams();
    !financingNeeds.allIds.length && dispatch(getFinancingNeeds(user?.ngoId));
  }, []);

  useEffect(() => {
    if (event) {
      event.error
        ? notification.error({
            message:
              event.action == 'addFavoriteDonor'
                ? "Le mécène n'a pas pu être ajouté à vos favoris"
                : "Le mécène n'a pas pu être retiré de vos favoris",
            duration: 2,
          })
        : notification.success({
            message:
              event.action == 'addFavoriteDonor'
                ? 'Le mécène a été ajouté à vos favoris'
                : 'Le mécène a été retiré de vos favoris',
            duration: 2,
          });
      dispatch(resetDonorEvent());
    }
  }, [event]);

  useEffect(() => {
    if (financingNeedEvent) {
      financingNeedEvent.error
        ? notification.error({
            message:
              financingNeedEvent.action == 'addDonor'
                ? "Le mécène n'a pas pu être ajouté au projet"
                : "Le mécène n'a pas pu être retiré du projet",
            duration: 2,
          })
        : notification.success({
            message:
              financingNeedEvent.action == 'addDonor'
                ? 'Le mécène a été ajouté au projet'
                : 'Le mécène a été retiré du projet',
            duration: 2,
          });
      dispatch(resetFinancingNeedEvent());
    }
  }, [financingNeedEvent]);

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 0;
    fetchDonors(
      newSearchPage,
      queryParamsObject.algorithm as string,
      searchFilters,
    );
  };

  const onSelectAlgorithm = (algorithm: string) => {
    const { page } = queryParamsObject;

    fetchDonors(
      page ? parseInt(page as string) : undefined,
      algorithm,
      searchFilters,
    );
  };

  function renderRadioButtons() {
    return (
      <div className={classes.select}>
        <Select
          defaultValue="tagsProximity"
          // style={{ width: 120 }}

          onChange={(e) => onSelectAlgorithm(e)}
          options={[
            {
              value: 'tagsProximity',
              label: 'Proximité par Mot-clés',
            },
            {
              value: 'ngosProximity',
              label: 'Proximité avec les autres porteurs de projets',
            },
            {
              value: 'donorsProximity',
              label: 'Proximité avec les autres mécènes',
            },
          ]}
        />
      </div>
    );
  }

  const onChangeName = (value: string) => {
    value.length
      ? dispatch(
          searchDonorsWithAlgorithmAndGetSuggestions({
            projectId: projectId,
            searchParameters: { ...searchFilters, offset: 0, name: value },
            algorithm:
              (queryParamsObject.algorithm as string) || 'tagsProximity',
          }),
        )
      : dispatch(resetSuggestions());
  };

  if (!user || !classification || !donors) {
    return <FullscreenSpin />;
  }

  useEffect(() => {
    fetchDonors(1, queryParamsObject.algorithm as string, searchFilters);
  }, [searchFilters]);

  const displayedFinancingNeed = useMemo<FinancingNeed>(
    () => financingNeeds.byId[projectId],
    [financingNeeds.byId[projectId]],
  );

  useEffect(() => {
    dispatch(getFinancingNeed(projectId));
  }, []);

  if (displayedFinancingNeed && isPremium) {
    return (
      <div
        className={classes.container}
        onClick={() => setDropdownFilterVisible(false)}
      >
        <div className={classes.header}>
          <Text
            className={classes.fondations}
            style={{ color: theme.palette.primary }}
          >
            Mécènes
          </Text>
          <div className={classes.filtersRow}>
            <DatabaseSearchBar
              classification={classification}
              onChangeName={onChangeName}
              suggestions={suggestionsDonors}
              suggestionsLoading={suggestionsLoading}
              isAutoComplete={false}
              searchParameters={searchFilters}
              setSearchFilters={setSearchFilters}
              classificationsFilter={classificationsFilter}
              isFromProject={true}
              financingNeed={financingNeeds.byId[projectId]}
              dropdownFilterVisible={dropdownFilterVisible}
              setDropdownFilterVisible={setDropdownFilterVisible}
              isNgo={true}
            />
            {renderRadioButtons()}
          </div>
        </div>
        <DonorCards
          donors={
            searchResult?.resultsIds?.reduce(
              (list: Donor[], donorId) =>
                donors.byId[donorId] ? [...list, donors.byId[donorId]] : list,
              [],
            ) || null
          }
          onTableChange={onTableChange}
          loading={loading}
          pagination={{
            total: min([
              searchResult?.resultsCount,
              DONORS_MAX_RESULTS * MAX_DISPLAYED_PAGES_NUMBER,
            ]),
            current: offset / DONORS_MAX_RESULTS + 1,
            pageSize: DONORS_MAX_RESULTS,
            showSizeChanger: false,
          }}
          financingNeedId={displayedFinancingNeed._id}
        />
      </div>
    );
  } else if (displayedFinancingNeed && !isPremium) {
    history.push('/projects');
    return (
      <div>
        <p>You do not have a valid subscription to access this feature.</p>
      </div>
    );
  } else {
    return <FullscreenSpin />;
  }
};
