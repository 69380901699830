import { Button, Table, TablePaginationConfig } from 'antd';
import { parse, stringify } from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import {
  DatabaseAdminSearchBar,
  SortOrder,
} from 'src/components/DatabseAdminSearchBar';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { fetchAllContributors } from 'src/slices/contributor';

import { Donor, DONORS_MAX_RESULTS, searchAllDonors } from 'src/slices/donor';
import { contributorsSelector } from 'src/store';
import { SearchFiltersAdminParameters } from '../components/DatabseAdminSearchBar';
import { getClassification } from '../slices/classification';
import watcheteLogo from '../assets/wachete.png';

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    paddingLeft: '1.25vw',
    paddingRight: '1.25vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  button: {
    display: 'block',
    margin: 'auto',
    padding: '0.25vw 0.9375vw',
    height: '2.4vw',
    fontSize: '0.875vw',
    width: '5vw',
    minHeight: '30px',
    minWidth: '60px',
    backgroundImage: `url(${watcheteLogo})`,
    backgroundSize: '100% 100%',
  },
  select: {
    display: 'block',
    marginRight: '4px',
    width: '10vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        color: 'black',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
  column: {
    color: 'black',
    fontWeight: 'bold',
    cursor: 'pointer',
    maxWidth: '18.75vw',
  },
  name: {
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '12.5vw',
  },
  published: {
    width: '8.5vw',
  },
  placeholder: {
    width: '12.5vw',
    color: 'black',
  },
  selectContainer: {
    display: 'flex',
    alignContent: 'left',
    paddingLeft: '10%',
  },
  searchContainer: {
    paddingLeft: '1.25vw',
    paddingRight: '1.25vw',
    marginBottom: '2vw',
    maxWidth: '100%',
    display: 'flex',
  },
  createContainer: {
    position: 'relative',
    display: 'flex',
    float: 'right',
    margin: '0 20px 10px 90px',
  },
  nbRef: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const Wachete: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const query = useLocation().search;
  const history = useHistory();

  const { donors, loading, searchResult, offset, saved, classification } =
    useAppSelector(
      ({
        donor: { donors, loading, searchResult, offset, saved },
        classification,
      }) => ({
        donors,
        loading,
        searchResult,
        offset,
        saved,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        classification: classification.classification!,
      }),
    );

  const contributors = useAppSelector(contributorsSelector.selectAll);

  const [searchFilters, setSearchFilters] =
    useState<SearchFiltersAdminParameters>({});

  let queryParamsObject: Record<string, unknown> = {};

  useEffect(() => {
    dispatch(fetchAllContributors());
    !classification && dispatch(getClassification());
    fetchDonorsWithQueryParams();
  }, []);

  useEffect(() => {
    if (saved) {
      dispatch(fetchAllContributors());
      fetchDonorsWithQueryParams();
    }
  }, [saved]);

  const donorsList = useMemo<Donor[]>(
    () =>
      donors.allIds.reduce(
        (accumulator: Donor[], current: string) =>
          donors.byId[current]
            ? [...accumulator, donors.byId[current]]
            : accumulator,
        [],
      ),
    [donors],
  );

  const updateQueryParams = (params: Record<string, unknown>) => {
    const { name, ...rest } = params;

    queryParamsObject = {
      ...queryParamsObject,
      ...rest,
      ...(name === '' ? {} : { name }),
    };
    history.replace({
      search: stringify(queryParamsObject, { arrayFormat: 'bracket' }),
    });
  };

  const fetchDonorsWithQueryParams = () => {
    const {
      page,
      name,
      tags,
      statuses,
      targetPersons,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      source,
      nationality,
      author,
      createdAtStart,
      createdAtEnd,
      updatedAtStart,
      updatedAtEnd,
    } = parse(query, { arrayFormat: 'bracket' });

    const {
      hasHomepageUrl,
      worksInIsolation,
      hasLinkedinUrl,
      isHostedBy,
      hasDescription,
      usesCallsToTender,
      hasComments,
    } = parse(query, {
      parseBooleans: true,
    });

    const {
      callForTendersCountStart,
      callForTendersCountEnd,
      callForTendersCount,
      fundedNgosCount,
      fundedNgosCountStart,
      fundedNgosCountEnd,
      unverifiedFundedNgosCount,
      unverifiedFundedNgosCountStart,
      unverifiedFundedNgosCountEnd,
      unverifiedAndVerifiedFundedNgosCount,
      unverifiedAndVerifiedFundedNgosCountStart,
      unverifiedAndVerifiedFundedNgosCountEnd,
    } = parse(query, { parseNumbers: true });

    const newSearchFilters = {
      name,
      tags,
      statuses,
      targetPersons,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      source,
      nationality,
      published: true,
      author,
      hasHomepageUrl,
      worksInIsolation,
      hasLinkedinUrl,
      isHostedBy,
      hasDescription,
      usesCallsToTender,
      hasComments,
      callForTendersCountStart,
      callForTendersCountEnd,
      callForTendersCount,
      fundedNgosCount,
      fundedNgosCountStart,
      fundedNgosCountEnd,
      unverifiedFundedNgosCount,
      unverifiedFundedNgosCountStart,
      unverifiedFundedNgosCountEnd,
      unverifiedAndVerifiedFundedNgosCount,
      unverifiedAndVerifiedFundedNgosCountStart,
      unverifiedAndVerifiedFundedNgosCountEnd,
      createdAtStart,
      createdAtEnd,
      updatedAtStart,
      updatedAtEnd,
    } as SearchFiltersAdminParameters;

    setSearchFilters(newSearchFilters);

    fetchDonors(newSearchFilters, page ? parseInt(page as string) : 1);
  };

  const fetchDonors = (
    searchFilters: SearchFiltersAdminParameters,
    page: number,
  ) => {
    updateQueryParams({ page, ...searchFilters });
    dispatch(
      searchAllDonors({
        ...searchFilters,
        offset: (page - 1) * DONORS_MAX_RESULTS,
      }),
    );
  };

  const onSearch = (value: SearchFiltersAdminParameters) => {
    setSearchFilters(value);
    fetchDonors(value, 1);
  };

  const onTableChange = (
    newPagination: TablePaginationConfig,
    filter: any,
    sorter: any,
  ) => {
    const order =
      sorter.order === 'ascend'
        ? SortOrder.ASC
        : sorter.order === 'descend'
        ? SortOrder.DESC
        : SortOrder.NAME;
    const newSearchPage = newPagination.current ?? 1;
    const newSearchFilters = {
      ...searchFilters,
      sort: order,
    };
    setSearchFilters(newSearchFilters);
    fetchDonors(newSearchFilters, newSearchPage);
  };

  const goToLink = (wacheteUrl: string) => {
    window.open(wacheteUrl, '_blank')?.focus();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      width: '20%',
      render: function renderId(_id: string, record: Donor) {
        const id = _id.substring(19, 24);
        return (
          <div
            className={classes.name}
            onClick={() => history.push(`/donor/${record._id}`)}
          >
            {id}
          </div>
        );
      },
    },
    {
      title: 'Nom',
      dataIndex: 'name',
      key: '_id',
      width: '20%',
      render: function renderName(name: string, record: Donor) {
        return (
          <div
            className={classes.name}
            onClick={() => history.push(`/donor/${record._id}`)}
          >
            {name}
          </div>
        );
      },
    },
    {
      title: 'Modus operandi',
      dataIndex: 'modusOperandi',
      key: '_id',
      width: '10%',
      render: function renderName(modusOperandi: string, record: Donor) {
        return (
          <div
            className={classes.name}
            onClick={() => history.push(`/donor/${record._id}`)}
          >
            {modusOperandi}
          </div>
        );
      },
    },
    {
      title: 'Lien Wachete 1',
      dataIndex: 'wacheteUrl',
      key: '_id',
      width: '10%',
      render: function renderWachete(wacheteUrl: string) {
        return (
          wacheteUrl && (
            <div className={classes.column}>
              <Button
                type="primary"
                className={classes.button}
                onClick={() => goToLink(wacheteUrl)}
              >
                .
              </Button>
            </div>
          )
        );
      },
    },
    {
      title: 'Lien Wachete 2',
      dataIndex: 'wacheteUrl2',
      key: '_id',
      width: '10%',
      render: function renderWachete(wacheteUrl2: string) {
        return (
          wacheteUrl2 && (
            <div className={classes.column}>
              <Button
                type="primary"
                className={classes.button}
                onClick={() => goToLink(wacheteUrl2)}
              >
                .
              </Button>
            </div>
          )
        );
      },
    },
    {
      title: 'Lien Wachete 3',
      dataIndex: 'wacheteUrl3',
      key: '_id',
      width: '10%',
      render: function renderWachete(wacheteUrl3: string) {
        return (
          wacheteUrl3 && (
            <div className={classes.column}>
              <Button
                type="primary"
                className={classes.button}
                onClick={() => goToLink(wacheteUrl3)}
              >
                .
              </Button>
            </div>
          )
        );
      },
    },
  ];

  return (
    <>
      <div className={classes.searchContainer}>
        <div style={{ width: '40%' }}>
          <DatabaseAdminSearchBar
            classification={classification}
            onSearch={onSearch}
            suggestions={null}
            suggestionsLoading={false}
            searchParameters={searchFilters}
            setSearchFilters={setSearchFilters}
            isFromProject={false}
            contributors={contributors}
          />
        </div>
      </div>
      <div className={classes.createContainer}></div>
      <Table
        className={classes.table}
        dataSource={donorsList}
        rowKey={(record) => record._id}
        columns={columns}
        onChange={onTableChange}
        loading={loading || !donors}
        pagination={{
          pageSize: DONORS_MAX_RESULTS,
          total: searchResult?.resultsCount,
          current: offset / DONORS_MAX_RESULTS + 1,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
      />
    </>
  );
};
