/* eslint-disable @typescript-eslint/no-unused-vars */
import { parse, stringify } from 'query-string';
import React, { useEffect, useState, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Table, Button, TablePaginationConfig, Select, Row } from 'antd';
import { useLocation } from 'react-router-dom';

import {
  Donor,
  DONORS_MAX_RESULTS,
  getDonorsForContributor,
  updateDonorAuthor,
} from 'src/slices/donor';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import {
  DatabaseSearchBar,
  SearchFiltersParameters,
} from '../components/DatabaseSearchBar';
import { Contributor, fetchAllContributors } from 'src/slices/contributor';
import { contributorsSelector } from 'src/store';
import Checkbox from 'antd/es/checkbox';
import { useHistory } from 'react-router-dom';
import Col from 'antd/es/grid/col';
import { getClassification } from '../slices/classification';

const { Option } = Select;

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
  },
  table: {
    maxWidth: '100%',
    paddingLeft: '1.25vw',
    paddingRight: '1.25vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  button: {
    display: 'block',
    margin: 'auto',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  select: {
    display: 'flex',
    margin: '1.25vw auto',
    width: '37.5vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        height: '2vw !important',
        lineHeight: '2vw !important',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
  name: {
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '12.5vw',
  },
});

export const AdminNeedToQualify: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const query = useLocation().search;
  const history = useHistory();

  const { donors, loading, searchResult, offset, saved, classification } =
    useAppSelector(
      ({
        donor: { donors, loading, searchResult, offset, saved },
        classification,
      }) => ({
        donors,
        loading,
        searchResult,
        offset,
        saved,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        classification: classification.classification!,
      }),
    );

  const contributors = useAppSelector(contributorsSelector.selectAll);
  const getContributorById = (id: string) =>
    contributors.find((contributor) => contributor._id == id);

  const [contributorsDonors, setContributorsDonors] = useState<string[]>([]);
  const [selectedContributor, setSelectedContributor] = useState<Contributor>();
  const [searchFilters, setSearchFilters] = useState<SearchFiltersParameters>(
    {},
  );
  let queryParamsObject: Record<string, unknown> = {};

  useEffect(() => {
    dispatch(fetchAllContributors());
    !classification && dispatch(getClassification());
    fetchDonorsWithQueryParams();
  }, []);

  useEffect(() => {
    if (saved) {
      dispatch(fetchAllContributors());
      fetchDonorsWithQueryParams();
    }
  }, [saved]);

  useEffect(() => {
    setContributorsDonors([]);
    donorsList.map((donor) => {
      if (donor.author == selectedContributor?._id) {
        contributorsDonors.push(donor._id);
      }
    });
  }, [selectedContributor]);
  const donorsList = useMemo<Donor[]>(
    () =>
      donors.allIds.reduce(
        (accumulator: Donor[], current: string) =>
          donors.byId[current]
            ? [...accumulator, donors.byId[current]]
            : accumulator,
        [],
      ),
    [donors],
  );

  const updateQueryParams = (params: Record<string, unknown>) => {
    const { name, ...rest } = params;

    queryParamsObject = {
      ...queryParamsObject,
      ...rest,
      ...(name === '' ? {} : { name }),
    };
    history.replace({
      search: stringify(queryParamsObject, { arrayFormat: 'bracket' }),
    });
  };

  const fetchDonorsWithQueryParams = () => {
    const {
      page,
      name,
      tags,
      statuses,
      targetPersons,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      source,
    } = parse(query, { arrayFormat: 'bracket' });

    const newSearchFilters = {
      name,
      tags,
      statuses,
      targetPersons,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      source,
    } as SearchFiltersParameters;

    setSearchFilters(newSearchFilters);

    fetchDonors(newSearchFilters, page ? parseInt(page as string) : 1);
  };

  const fetchDonors = async (
    searchFilters: SearchFiltersParameters,
    page: number,
  ) => {
    updateQueryParams({ page, ...searchFilters });
    dispatch(
      getDonorsForContributor({
        offset: (page - 1) * DONORS_MAX_RESULTS,
        statusFilter: 'rawData',
        limit: DONORS_MAX_RESULTS,
        hasAuthor: false,
        ...searchFilters,
      }),
    );
  };

  useEffect(() => {
    fetchDonors(searchFilters, 1);
  }, [searchFilters]);

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 1;
    fetchDonors(searchFilters, newSearchPage);
  };

  const onSelect = (value: string) => {
    const contributor = getContributorById(value);
    setSelectedContributor(contributor);
  };

  const onCheck = (value: boolean, donorId: string) => {
    const contributorsDonorsCopy = [...contributorsDonors];
    contributorsDonorsCopy.includes(donorId)
      ? contributorsDonorsCopy.splice(
          contributorsDonorsCopy.indexOf(donorId),
          1,
        )
      : contributorsDonorsCopy.push(donorId);
    setContributorsDonors(contributorsDonorsCopy);
  };

  const onCheckAll = () => {
    const contributorsDonorsCopy = [...contributorsDonors];
    donorsList.map((donor) => {
      if (!contributorsDonorsCopy.includes(donor._id)) {
        contributorsDonorsCopy.push(donor._id);
      }
    });
    setContributorsDonors(contributorsDonorsCopy);
  };

  const onReset = () => {
    setContributorsDonors([]);
  };

  const onSubmit = () => {
    if (selectedContributor?._id)
      dispatch(
        updateDonorAuthor({
          donorsIds: contributorsDonors,
          authorId: selectedContributor?._id,
        }),
      );
  };

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: '_id',
      render: function renderName(name: string, record: Donor) {
        return (
          <div
            className={classes.name}
            onClick={() => history.push(`/donor/${record._id}`)}
          >
            {name}
          </div>
        );
      },
    },
    {
      title: 'Priorité',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Assigner',
      key: 'addToContributor',
      render: function renderAddContributor(name: string, record: Donor) {
        return (
          <Checkbox
            onChange={(e) => {
              onCheck(e.target.checked, record._id);
            }}
            checked={contributorsDonors.includes(record._id)}
          />
        );
      },
    },
  ];

  const [dropdownFilterVisible, setDropdownFilterVisible] = useState(false);

  return (
    <div
      className={classes.container}
      onClick={() => setDropdownFilterVisible(false)}
    >
      <DatabaseSearchBar
        classification={classification}
        suggestions={null}
        isAutoComplete={false}
        suggestionsLoading={false}
        searchParameters={searchFilters}
        setSearchFilters={setSearchFilters}
        isFromProject={false}
        dropdownFilterVisible={dropdownFilterVisible}
        setDropdownFilterVisible={setDropdownFilterVisible}
      />
      <Select
        placeholder="Sélectionner un contributeur"
        className={classes.select}
        onSelect={onSelect}
      >
        {contributors.map((contributor, index) => (
          <Option
            key={index}
            value={contributor._id}
            label={`${contributor.firstName ?? ''} ${
              contributor.lastName ?? ''
            } (${contributor.rawData})`}
          >
            {`${contributor.firstName ?? ''} ${contributor.lastName ?? ''} (${
              contributor.rawData ?? 0
            })`}
          </Option>
        ))}
      </Select>
      <Row align="middle" style={{ float: 'right', margin: '0 20px 20px' }}>
        <Col style={{ marginRight: '4px' }}>
          <Button onClick={onCheckAll} className={classes.button}>
            Tout sélectionner
          </Button>
        </Col>
        <Col>
          <Button onClick={onReset} className={classes.button}>
            Tout désélectionner
          </Button>
        </Col>
      </Row>
      <Table
        className={classes.table}
        dataSource={donorsList}
        rowKey={(record) => record._id}
        columns={columns}
        onChange={onTableChange}
        loading={loading || !donors}
        pagination={{
          pageSize: DONORS_MAX_RESULTS,
          total: searchResult?.resultsCount,
          current: offset / DONORS_MAX_RESULTS + 1,
          showSizeChanger: false,
        }}
      />
      <Button className={classes.button} type="primary" onClick={onSubmit}>
        Confirmer
      </Button>
    </div>
  );
};
