import React, { useEffect, useMemo } from 'react';
import { Typography } from 'antd';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/store';
import { useDispatch } from 'react-redux';
import { findUserById } from 'src/slices/auth';

interface Props {
  noteId: string;
}

const useStyles = createUseStyles({
  noteContainer: {
    background: '#F7F8FC',
  },
  noteContent: {
    padding: '10px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    margin: '8px',
  },
  noteDate: {
    padding: '10px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#737373',
    marginBottom: '7px',
    textAlign: 'right',
  },
});

export const KanbanNote: React.VFC<Props> = ({ noteId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { notes } = useAppSelector(({ dealFlowNote: { notes } }) => ({
    notes,
  }));

  const note = useMemo(() => notes && notes[noteId], [notes, noteId]);

  useEffect(() => {
    if (note && !note.author) {
      dispatch(findUserById({ userId: note.author }));
    }
  }, [note]);

  const findTimeInterval = (
    time: number,
  ): [
    number,
    'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years',
  ] => {
    const timeUnits = [31536000, 2628002, 604800, 86400, 3600, 60, 0];
    const timeUnitNames: (
      | 'seconds'
      | 'minutes'
      | 'hours'
      | 'days'
      | 'weeks'
      | 'months'
      | 'years'
    )[] = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];
    let index = timeUnits.findIndex((unit) => time >= unit);
    if (index < 0) index = 6;
    const timeInterval = Math.floor(
      time / (timeUnits[index] > 0 ? timeUnits[index] : 1),
    );
    return [timeInterval, timeUnitNames[index]];
  };

  const renderDate = () => {
    if (!note) return null;
    const date = new Date(note.createdAt).getTime();
    const now = new Date().getTime();
    const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'narrow' });
    const timeInterval = findTimeInterval((now - date) / 1000);
    const relativeTime = rtf1.format(-1 * timeInterval[0], timeInterval[1]);
    return <Typography className={classes.noteDate}>{relativeTime}</Typography>;
  };

  if (!note) return null;

  return (
    <div className={classes.noteContainer}>
      {/* Comment Content */}
      <p className={classes.noteContent}>{note.content}</p>

      {/* Date */}
      {renderDate()}
    </div>
  );
};
