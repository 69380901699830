import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { getDealFlow } from 'src/slices/dealFlow';
import { Kanban } from 'src/containers/Kanban';
import { Typography, Modal, Spin } from 'antd';
import {
  CallForTender,
  getCallForTender,
  removeCallForTender,
} from '../slices/callForTender';

const { Text } = Typography;

interface Props {
  callForTender: CallForTender;
}

const useStyles = createUseStyles({
  projectKanban: {
    minHeight: '100%',
    display: 'inline-flex',
    width: '100%',
    padding: '2.8% 3.45%',
  },
  kanbanContainer: {
    flex: '1',
    height: '100%',
  },
  spinContainer: {
    display: 'flex',
    padding: 'auto',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const ProjectKanbanDonors: React.VFC<Props> = ({ callForTender }) => {
  const classes = useStyles();
  const { callForTEnderId } = useParams<{ callForTEnderId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { loading, user } = useAppSelector(
    ({ dealFlow: { loading }, auth: { user } }) => ({
      loading,
      user,
    }),
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCallForTender(callForTEnderId));
  }, [callForTEnderId]);

  useEffect(() => {
    if (callForTender) dispatch(getDealFlow(callForTender.dealFlow));
  }, [callForTender]);

  const handleRemoveCallForTender = () => {
    if (user?.donorId) {
      dispatch(
        removeCallForTender({
          callForTenderId: callForTender._id,
          body: { donorId: user._id },
        }),
      );
      history.goBack();
    }
  };

  const renderConfirmDelete = () => (
    <Modal
      onOk={handleRemoveCallForTender}
      okText={'Oui'}
      cancelText={'Annuler'}
      visible={showDeleteModal}
      onCancel={() => setShowDeleteModal(false)}
    >
      <Text>
        {'Ètes-vous sûrs de vouloir supprimer ce programme de soutien ?'}
      </Text>
    </Modal>
  );
  if (loading) {
    return (
      <div className={classes.spinContainer}>
        <Spin />
      </div>
    );
  }

  return (
    <div className={classes.projectKanban}>
      <div className={classes.kanbanContainer}>
        <Kanban />
      </div>
      {renderConfirmDelete()}
    </div>
  );
};
