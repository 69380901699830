import React, { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Popover,
  TablePaginationConfig,
  Select,
  Button,
  Divider,
} from 'antd';
import {
  DONORS_MAX_RESULTS,
  updateDonorAuthor,
  removeDonorAuthor,
} from 'src/slices/donor';
import { getDonorsForContributor } from 'src/slices/donor';
import { Donor } from '../slices/donor';
import { contributorsSelector } from 'src/store';
import { fetchAllContributors } from 'src/slices/contributor';

const { Option } = Select;

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    padding: '1vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  button: {
    display: 'block',
    margin: 'auto',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  descriptionPopup: {
    maxWidth: '25vw',
    maxHeight: '50vh',
    overflow: 'auto',
  },
  descriptionText: {
    cursor: 'default',
  },
  name: {
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '12.5vw',
    fontSize: '0.875vw',
  },
  select: {
    display: 'flex',
    margin: '0.625vw 0 auto',
    width: '18.75vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        height: '2vw !important',
        lineHeight: '2vw !important',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
});

type DonorContributor = {
  [donor: string]: string;
};

export const AdminAssigned: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { donors, searchResult, offset, saved } = useAppSelector(
    ({ donor: { donors, searchResult, offset, saved } }) => ({
      donors,
      searchResult,
      offset,
      saved,
    }),
  );
  const contributors = useAppSelector(contributorsSelector.selectAll);

  const [contributorsDonors, setContributorsDonors] =
    useState<DonorContributor>({});

  const donorsList = useMemo<Donor[]>(
    () =>
      donors.allIds.reduce(
        (accumulator: Donor[], current: string) =>
          donors.byId[current]
            ? [...accumulator, donors.byId[current]]
            : accumulator,
        [],
      ),
    [donors],
  );

  const onSelect = (value: string, donorId: string) => {
    const newObject = Object.assign(contributorsDonors, { [donorId]: value });
    setContributorsDonors(newObject);
  };

  const onSubmit = () => {
    if (contributorsDonors)
      Object.entries(contributorsDonors).map(
        ([key, value]) =>
          value &&
          dispatch(
            updateDonorAuthor({
              donorsIds: [key],
              authorId: value,
            }),
          ),
      );
    setContributorsDonors({});
  };

  const handleRemoveDonorAuthor = (_id: string) => {
    dispatch(removeDonorAuthor({ _id: _id, publicationStatus: '1' })); // TODO: change with correct number
    dispatch(
      getDonorsForContributor({
        offset: offset,
        statusFilter: 'assigned',
        limit: DONORS_MAX_RESULTS,
        hasAuthor: true,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchAllContributors());
  }, [saved]);

  function renderModifContributor(name: string, record: Donor) {
    return (
      <>
        <Select
          placeholder="Sélectionner un contributeur"
          className={classes.select}
          onSelect={(value: string) => onSelect(value, record._id)}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  padding: '8px',
                }}
              >
                <a
                  style={{
                    flex: 'none',
                    padding: '2px',
                    display: 'block',
                    cursor: 'pointer',
                    fontSize: '0.875vw',
                  }}
                  onClick={() => handleRemoveDonorAuthor(record._id)}
                >
                  Renvoyer à assigner
                </a>
              </div>
            </div>
          )}
        >
          {contributors.map((contributor, index) => (
            <Option
              key={index}
              value={contributor._id}
              label={`${contributor.firstName ?? ''} ${
                contributor.lastName ?? ''
              } (${contributor.rawData})`}
              style={{ fontSize: '0.875vw' }}
            >
              {`${contributor.firstName ?? ''} ${contributor.lastName ?? ''} (${
                contributor.rawData ?? 0
              })`}
            </Option>
          ))}
        </Select>
      </>
    );
  }
  const columns = useMemo(
    () =>
      donorsList
        ? [
            {
              title: 'Nom',
              dataIndex: 'name',
              key: '_id',
              fixed: 'left' as const,
              render: function renderName(name: string, record: Donor) {
                return (
                  <Popover
                    content={
                      <div className={classes.descriptionPopup}>
                        <div className={classes.descriptionText}>
                          {record.description}
                        </div>
                      </div>
                    }
                  >
                    <div
                      className={classes.name}
                      onClick={() => history.push(`/donor/${record._id}`)}
                    >
                      {name}
                    </div>
                  </Popover>
                );
              },
            },
            {
              title: 'Priorité',
              dataIndex: 'priority',
              key: 'priority',
            },
            {
              title: 'Contributeur',
              dataIndex: 'author',
              key: 'author',
              render: function renderContributor(authorId: string) {
                const contributor = contributors.find(
                  (author) => author._id === authorId,
                );
                return (
                  <div className={classes.name}>{`${
                    contributor?.firstName ?? ''
                  } ${contributor?.lastName ?? ''}`}</div>
                );
              },
            },
            {
              title: 'Modifier le contributeur',
              dataIndex: 'modifAuthor',
              key: 'modifAuthor',
              render: renderModifContributor,
            },
          ]
        : [],
    [contributors, donorsList],
  );
  useEffect(() => {
    fetchDonors(1);
  }, []);

  const fetchDonors = (page: number) => {
    dispatch(
      getDonorsForContributor({
        offset: (page - 1) * DONORS_MAX_RESULTS,
        statusFilter: 'assigned',
        limit: DONORS_MAX_RESULTS,
        hasAuthor: true,
      }),
    );
  };

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 0;
    fetchDonors(newSearchPage);
  };

  return (
    <>
      <Table
        className={classes.table}
        dataSource={donorsList}
        columns={columns}
        pagination={{
          total: searchResult?.resultsCount,
          current: offset / DONORS_MAX_RESULTS + 1,
          pageSize: DONORS_MAX_RESULTS,
          showSizeChanger: false,
        }}
        onChange={onTableChange}
        rowKey="_id"
      />
      <Button onClick={onSubmit} className={classes.button} type="primary">
        Confirmer
      </Button>
    </>
  );
};
