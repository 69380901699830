import { Radio } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ProjectKanbanNgos } from 'src/containers/ProjectKanbanNgos';
import { FinancingNeed, getFinancingNeed } from 'src/slices/financingNeed';
import { LoggedIntercom } from 'src/utils/loggedIntercom';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { ProjectDonorsCards } from '../containers/ProjectDonorsCards';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { getNgo } from '../slices/ngo';
import { isOrganizationPremium } from '../utils/projects';

const useStyles = createUseStyles({
  container: {
    height: '100%',
    width: '100%',
    backgroundImage: `url("../../images/dashboardLogo.png")`,
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    background:
      'radial-gradient(ellipse at top right, #d2dcfd 0%, #f0f2ff 20%, transparent 50%),\
      radial-gradient(ellipse at top left, #fdfefe 0%, #f4f7ff 20%, transparent 50%),\
      radial-gradient(ellipse at bottom left, #e5ecfe, #f6f9fe 30%, transparent 50%),\
      radial-gradient(ellipse at bottom right, #ffffff, #fbfbfb 5%, #f8fcfd 20%, #f0f2ff 30%, transparent 75%)',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '2.8%',
  },
  radioGroup: {
    '& .ant-radio-button-wrapper': {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      background: '#fff',
      color: '#4F46E5',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      padding: '8px 16px',
      borderRadius: '6px',
      textAlign: 'center',
    },
    '& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)':
      {
        background: '#EEF2FF',
        color: '#4F46E5',
        borderRadius: '6px',
      },
    '& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before':
      {
        backgroundColor: 'transparent',
      },
    '& .ant-radio-button-wrapper:not(:first-child)::before': {
      display: 'none',
    },
    '& .ant-radio-button-wrapper:hover': {
      background: '#DDE7FF',
    },
  },
});

export const ProjectDonors: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const defaultValue = 1;

  const [selectedTab, setSelectedTab] = useState<0 | 1>(defaultValue);

  const { financingNeeds, user } = useAppSelector(
    ({
      donor: {
        donors,
        loading,
        event,
        suggestionsLoading,
        searchResult,
        suggestionsDonors,
        offset,
        classificationsFilter,
      },
      financingNeed: {
        financingNeeds,
        addDonorLoading,
        removeDonorLoading,
        event: financingNeedEvent,
      },
      auth: { user },
    }) => ({
      donors,
      classificationsFilter,
      financingNeeds,
      loading,
      event,
      financingNeedEvent,
      suggestionsLoading,
      addDonorLoading,
      removeDonorLoading,
      searchResult,
      suggestionsDonors,
      user,
      offset,
    }),
  );

  const isPremium = useMemo(() => {
    return isOrganizationPremium(Object.values(financingNeeds.byId));
  }, [financingNeeds]);

  LoggedIntercom(
    user?.firstName + ' ' + user?.lastName,
    user?.email,
    user?._id,
    'financingNeedSawTrackingTable',
  );

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getNgo(user?.ngoId));
    }
  }, [user, dispatch]);

  useEffect(() => {
    dispatch(getFinancingNeed(projectId));
  }, [dispatch, projectId]);

  const displayedFinancingNeed = useMemo<FinancingNeed | undefined>(() => {
    if (!projectId) {
      return undefined;
    }
    return financingNeeds.byId[projectId];
  }, [projectId, financingNeeds.byId[projectId]]);

  const onSelectTab = (tab: 1 | 0) => {
    setSelectedTab(tab);
  };

  if (!displayedFinancingNeed) {
    return <FullscreenSpin />;
  }

  if (displayedFinancingNeed && isPremium) {
    return (
      <div className={classes.container}>
        <div className={classes.buttonsContainer}>
          <Radio.Group
            defaultValue={defaultValue}
            buttonStyle="outline"
            onChange={(e) => onSelectTab(e.target.value)}
            className={classes.radioGroup}
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'center',
            }}
          >
            <Radio.Button
              value={0}
              style={{ color: selectedTab === 1 ? 'black' : '' }}
            >
              Vue kanban
            </Radio.Button>
            <Radio.Button
              value={1}
              style={{ color: selectedTab === 0 ? 'black' : '' }}
            >
              Vue vignettes
            </Radio.Button>
          </Radio.Group>
        </div>
        {selectedTab === 0 ? (
          <ProjectKanbanNgos financingNeed={displayedFinancingNeed} />
        ) : (
          <div style={{ paddingTop: '32px' }}>
            <ProjectDonorsCards financingNeed={displayedFinancingNeed} />
          </div>
        )}
      </div>
    );
  } else if (displayedFinancingNeed && !isPremium) {
    history.push('/projects');
    return (
      <div>
        <p>You do not have a valid subscription to access this feature.</p>
      </div>
    );
  } else {
    return <FullscreenSpin />;
  }
};
