import { notification, TablePaginationConfig, Typography } from 'antd';
import { parse, stringify } from 'query-string';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation, useHistory } from 'react-router-dom';

import {
  DatabaseSearchBar,
  SearchFiltersParameters,
} from 'src/components/DatabaseSearchBar';
import { DonorsTable } from 'src/components/DonorsTable';
import { useSortedClassification } from 'src/hooks/useSortedClassification';
import {
  addDonor,
  getFinancingNeeds,
  removeDonor,
  resetFinancingNeedEvent,
} from 'src/slices/financingNeed';
import { getNgo } from 'src/slices/ngo';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  Donor,
  DONORS_MAX_RESULTS,
  resetDonorEvent,
  resetSuggestions,
  searchDonors,
  searchDonorsAndGetSuggestions,
} from '../slices/donor';

const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // justifyContent: 'center',
  },
  fondations: {
    paddingLeft: '5.59%',
    paddingTop: '4.59%',
    fontWeight: '600',
    fontFamily: "'Baloo 2'",
    fontSize: '2.125vw',
    lineHeight: '1',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const Database: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const query = useLocation().search;
  const [searchFilters, setSearchFilters] = useState<SearchFiltersParameters>(
    {},
  );
  const [queryParamsObject, setQueryParamsObject] = useState<
    Record<string, unknown>
  >(parse(query, { arrayFormat: 'bracket' }));

  const {
    donors,
    financingNeeds,
    loading,
    event,
    suggestionsLoading,
    addDonorLoading,
    removeDonorLoading,
    searchResult,
    suggestionsDonors,
    ngo,
    user,
    offset,
  } = useAppSelector(
    ({
      donor: {
        donors,
        loading,
        event,
        suggestionsLoading,
        searchResult,
        suggestionsDonors,
        offset,
      },
      financingNeed: { financingNeeds, addDonorLoading, removeDonorLoading },
      ngo: { ngo },
      auth: { user },
    }) => ({
      donors,
      financingNeeds,
      loading,
      event,
      suggestionsLoading,
      addDonorLoading,
      removeDonorLoading,
      searchResult,
      suggestionsDonors,
      ngo,
      user,
      offset,
    }),
  );

  const financingNeedEvent = useAppSelector(({ financingNeed: { event } }) => ({
    event,
  })).event;

  const classification = useSortedClassification();

  const fetchDonors = (
    searchFilters: SearchFiltersParameters,
    page: number,
  ) => {
    updateQueryParams({ page, ...searchFilters });
    dispatch(
      searchDonors({
        ...searchFilters,
        offset: (page - 1) * DONORS_MAX_RESULTS,
      }),
    );
  };

  const fetchDonorsWithQueryParams = () => {
    const {
      page,
      name,
      tags,
      statuses,
      targetPersons,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      donationTypes,
    } = parse(query, { arrayFormat: 'bracket' });

    const newSearchFilters = {
      name,
      tags,
      statuses,
      targetPersons,
      activityDomains,
      activityZones,
      sustainableDevelopmentGoals,
      targetPopulations,
      donationTypes,
    } as SearchFiltersParameters;

    setSearchFilters(newSearchFilters);

    fetchDonors(newSearchFilters, page ? parseInt(page as string) : 1);
  };

  const updateQueryParams = (params: Record<string, unknown>) => {
    const { name, ...rest } = params;
    const updatedQueryParamsObject = {
      ...queryParamsObject,
      ...rest,
      ...(name === '' ? {} : { name }),
    };

    setQueryParamsObject(updatedQueryParamsObject);

    history.replace({
      search: stringify(updatedQueryParamsObject, { arrayFormat: 'bracket' }),
    });
  };

  useEffect(() => {
    fetchDonorsWithQueryParams();
    !financingNeeds.allIds.length && dispatch(getFinancingNeeds(user?.ngoId));
    !ngo && dispatch(getNgo(user?.ngoId));
  }, []);

  useEffect(() => {
    if (ngo) {
      const presetSearchParameters =
        queryParamsObject['presetSearchParameters'] == 'true';

      if (presetSearchParameters) {
        setSearchFilters({ tags: ngo.tags });
      } else {
        fetchDonorsWithQueryParams();
      }
    }
  }, [ngo]);

  useEffect(() => {
    if (event) {
      event.error
        ? notification.error({
            message:
              event.action == 'addFavoriteDonor'
                ? "Le mécène n'a pas pu être ajouté à vos favoris"
                : "Le mécène n'a pas pu être retiré de vos favoris",
            duration: 2,
          })
        : notification.success({
            message:
              event.action == 'addFavoriteDonor'
                ? 'Le mécène a été ajouté à vos favoris'
                : 'Le mécène a été retiré de vos favoris',
            duration: 2,
          });
      dispatch(resetDonorEvent());
    }
  }, [event]);

  useEffect(() => {
    if (financingNeedEvent) {
      financingNeedEvent.error
        ? notification.error({
            message:
              financingNeedEvent.action == 'addDonor'
                ? "Le mécène n'a pas pu être ajouté au projet"
                : "Le mécène n'a pas pu être retiré du projet",
            duration: 2,
          })
        : notification.success({
            message:
              financingNeedEvent.action == 'addDonor'
                ? 'Le mécène a été ajouté au projet'
                : 'Le mécène a été retiré du projet',
            duration: 2,
          });
      dispatch(resetFinancingNeedEvent());
    }
  }, [financingNeedEvent]);

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 0;
    fetchDonors(searchFilters, newSearchPage);
  };

  const onChangeName = (value: string) => {
    value.length
      ? dispatch(
          searchDonorsAndGetSuggestions({
            ...searchFilters,
            offset: 0,
            name: value,
          }),
        )
      : dispatch(resetSuggestions());
  };

  useEffect(() => {
    fetchDonors(searchFilters, 1);
  }, [searchFilters]);

  const onAddDonor = (donorId: string, financingNeedId: string) => {
    dispatch(
      addDonor({
        financingNeedId: financingNeedId,
        body: { donorId: donorId },
      }),
    );
  };

  const onRemoveDonor = (donorId: string, financingNeedId: string) => {
    dispatch(
      removeDonor({
        financingNeedId: financingNeedId,
        body: { donorId: donorId },
      }),
    );
  };

  const onEditDonor = (donorId: string) => {
    history.push(`/donor/${donorId}/edit`);
  };

  const [dropdownFilterVisible, setDropdownFilterVisible] = useState(false);

  if (!user || !classification || !donors) {
    return <FullscreenSpin />;
  }

  return (
    <div
      className={classes.container}
      onClick={() => setDropdownFilterVisible(false)}
    >
      <div className={classes.header}>
        <Text className={classes.fondations}>Mécènes</Text>
        <DatabaseSearchBar
          classification={classification}
          onChangeName={onChangeName}
          isAutoComplete={true}
          suggestions={suggestionsDonors}
          suggestionsLoading={suggestionsLoading}
          searchParameters={searchFilters}
          setSearchFilters={setSearchFilters}
          isFromProject={false}
          dropdownFilterVisible={dropdownFilterVisible}
          setDropdownFilterVisible={setDropdownFilterVisible}
        />
      </div>
      <DonorsTable
        donors={
          searchResult?.resultsIds?.reduce(
            (list: Donor[], donorId) =>
              donors.byId[donorId] ? [...list, donors.byId[donorId]] : list,
            [],
          ) || null
        }
        classification={classification}
        financingNeeds={financingNeeds.allIds.map(
          (financingNeedId) => financingNeeds.byId[financingNeedId],
        )}
        onTableChange={onTableChange}
        onAddDonor={onAddDonor}
        onRemoveDonor={onRemoveDonor}
        onEditDonor={onEditDonor}
        loading={loading}
        updateDonorLoading={addDonorLoading || removeDonorLoading}
        pagination={{
          total: searchResult?.resultsCount,
          current: offset / DONORS_MAX_RESULTS + 1,
          pageSize: DONORS_MAX_RESULTS,
          showSizeChanger: false,
        }}
        role={user.role}
      />
    </div>
  );
};
