import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { createUseStyles } from 'react-jss';
import { Table, TablePaginationConfig } from 'antd';
import { Donor, DONORS_MAX_RESULTS } from 'src/slices/donor';
import { getDonorsForContributor } from 'src/slices/donor';

const useStyles = createUseStyles({
  table: {
    maxWidth: '100%',
    paddingLeft: '1.25vw',
    paddingRight: '1.25vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  name: {
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '12.5vw',
  },
});

export const DonorsNotRelevant: React.VFC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { donors, searchResult, offset, user } = useAppSelector(
    ({ donor, auth }) => ({
      donors: donor.donors,
      searchResult: donor.searchResult,
      offset: donor.offset,
      user: auth.user,
    }),
  );
  const args = {
    limit: DONORS_MAX_RESULTS,
    offset: 0,
    statusFilter: 'notRelevant',
    hasAuthor: user?.role === 'contributor',
  };
  const columns = [
    {
      title: 'Nom',
      dataIndex: 'name',
      key: '_id',
      render: function renderName(name: string, record: Donor) {
        return (
          <div
            className={classes.name}
            onClick={() => history.push(`/donor/${record._id}`)}
          >
            {name}
          </div>
        );
      },
    },
    {
      title: 'Année de création',
      dataIndex: 'creationYear',
      key: 'creationYear',
    },
    {
      title: 'Adresse',
      dataIndex: 'headquartersAddress',
      key: 'headquartersAddress',
    },
  ];
  useEffect(() => {
    dispatch(getDonorsForContributor(args));
  }, []);

  const fetchDonors = (page: number) => {
    dispatch(
      getDonorsForContributor({
        offset: (page - 1) * DONORS_MAX_RESULTS,
        statusFilter: 'notRelevant',
        limit: DONORS_MAX_RESULTS,
        hasAuthor: user?.role === 'contributor',
      }),
    );
  };

  const onTableChange = (newPagination: TablePaginationConfig) => {
    const newSearchPage = newPagination.current ?? 0;
    fetchDonors(newSearchPage);
  };

  return (
    <>
      <Table
        className={classes.table}
        dataSource={searchResult?.resultsIds?.map(
          (donorId) => donors.byId[donorId],
        )}
        columns={columns}
        pagination={{
          total: searchResult?.resultsCount,
          current: offset / DONORS_MAX_RESULTS + 1,
          pageSize: DONORS_MAX_RESULTS,
          showSizeChanger: false,
        }}
        onChange={onTableChange}
        rowKey="_id"
      />
    </>
  );
};
