import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { SortOrder } from '../components/DatabseAdminSearchBar';

import { apiRequest } from '../helpers/api';
import { createAddRemoveObject } from '../utils/objects';

// TODO add type details
export const DONORS_MAX_RESULTS = 10;

enum DonorAction {
  ADD_FAVORITE_DONOR = 'addFavoriteDonor',
  REMOVE_FAVORITE_DONOR = 'removeFavoriteDonor',
  GET_FAVORITE_DONORS = 'getFavoriteDonors',
  GET_DONORS = 'getDonors',
  SEARCH_DONORS = 'searchDonors',
  GET_DISPLAYED_DONOR = 'getDonor',
}

interface DonorEvent {
  action: DonorAction;
  message?: string;
  error?: any;
}

export enum ModusOperandi {
  permanent = 'Appel à projet permanent - dépôt continu',
  temporary = 'Appel à projet temporaire',
  priceAndConcourse = 'Prix / concours',
  stock = 'Bourse',
  contact = 'Contacter directement le Mécène',
  recommandationsOnly = "Uniquement via recommandation d'un collabrateur",
  noContact = 'Ne souhaite pas être contacté',
  other = 'Autre / Non précisé',
}

export interface ClassificationsFilter {
  tags: string[];
  statuses: string[];
  nationalities: string[];
  targetPersons: string[];
  activityDomains: string[];
  activityZones: string[];
  sustainableDevelopmentGoals: string[];
  targetPopulations: string[];
  donationTypes: string[];
}

export type Donor = {
  _id: string;
  ogImageUrl?: string;
  name: string;
  tags?: string[];
  places?: string[];
  city?: string;
  roadAndNumber?: string;
  zipcode?: string;
  uniqueZipcode?: string;
  tagsFromFundedNgos: string[];
  commonTags?: string[];
  score?: number;
  activityDomains?: string[];
  sustainableDevelopmentGoals?: string[];
  status?: string;
  targetPerson?: string[];
  activityZones?: string[];
  donationTypes?: string[];
  description?: string;
  object?: string;
  headquarterAddress?: string;
  headquarterZipcode?: string;
  headquarterCity?: string;
  creationYear?: string;
  homepageUrl?: string;
  linkedinUrl?: string;
  wacheteUrl?: string;
  wacheteUrl2?: string;
  wacheteUrl3?: string;
  hostedBy?: string;
  contactEmail?: string;
  contactPhone?: string;
  averageDonation?: number;
  currentYearBudget?: number;
  usesCallsToTender?: boolean;
  author?: string;
  targetPopulations?: string[];
  publicationStatus?: string;
  published?: boolean;
  fundedNgos?: string[];
  unverifiedFundedNgos?: string[];
  comments?: string;
  sources?: string[];
  fileUrls?: string[];
  logoUrl?: string;
  logoName?: string;
  nationality?: 'France' | 'Suisse' | 'Belgique' | 'Luxembourg' | 'Other';
  privateOrPublic?: 'private' | 'public';
  worksInIsolation?: boolean;
  companyLinkedinUrl?: string;
  otherUrl?: string;
  callForTenders?: string[];
  openedCallForTenders?: string[];
  lastCallForTenderValidation?: Date;
  validatedAt?: Date;
  modusOperandi?: string[];
  ngos?: string[];
  countRef?: number;
  createdAt: Date;
};

export type DonorName = {
  _id: string;
  name: string;
};

export type UpdateDonorDto = {
  _id: string;
  name?: string;
  tags?: string[];
  places?: string[];
  city?: string;
  roadAndNumber?: string;
  zipcode?: string;
  uniqueZipcode?: string;
  activityDomains?: string[];
  sustainableDevelopmentGoals?: string[];
  status?: string;
  targetPerson?: string[];
  activityZones?: string[];
  fundedNgos?: string[];
  unverifiedFundedNgos?: string[];
  donationTypes?: string[];
  targetPopulations?: string[];
  description?: string;
  headquartersAddress?: string;
  creationYear?: string;
  homepageUrl?: string;
  linkedinUrl?: string;
  hostedBy?: string;
  contactEmail?: string;
  contactPhone?: string;
  averageDonation?: number;
  currentYearBudget?: number;
  author?: string;
  publicationStatus?: string;
  published?: boolean;
  fileUrls?: string[];
  logoUrl?: string;
  logoName?: string;
  nationality?: 'France' | 'Suisse' | 'Belgique' | 'Luxembourg' | 'Other';
  privateOrPublic?: 'private' | 'public';
  worksInIsolation?: boolean;
  companyLinkedinUrl?: string;
  otherUrl?: string;
  callForTenders?: string[];
  lastCallForTenderValidation?: Date;
  programModalities?: string[];
  programTypes?: string[];
  wacheteUrl?: string;
  wacheteUrl2?: string;
  wacheteUrl3?: string;
};

type SearchResults = {
  items: Donor[];
  totalItems: number;
};

type SearchParameters = {
  name?: string;
  tags?: string[];
  statuses?: string[];
  nationalities?: string[];
  targetPersons?: string[];
  activityDomains?: string[];
  activityZones?: string[];
  sustainableDevelopmentGoals?: string[];
  targetPopulations?: string[];
  donationTypes?: string[];
  source?: string;
  offset: number;
  department?: string[];
  region?: string[];
  limit?: number;
};

export interface SaveContributorDonorDto {
  name: string;

  siret?: string;

  headquarterAddress?: string;

  websiteUrl?: string;
}

type SearchParametersAdmin = {
  name?: string;
  tags?: string[];
  statuses?: string[];
  nationalities?: string[];
  targetPersons?: string[];
  activityDomains?: string[];
  activityZones?: string[];
  sustainableDevelopmentGoals?: string[];
  targetPopulations?: string[];
  donationTypes?: string[];
  source?: string;
  offset: number;
  hasLogo?: boolean;
  nationality?: string;
  publicationStatus?: string;
  hasHomepageUrl?: boolean;
  worksInIsolation?: boolean;
  hasLinkedinUrl?: boolean;
  hasCompanyLinkedinUrl?: boolean;
  isHostedBy?: boolean;
  hasDescription?: boolean;
  usesCallsToTender?: boolean;
  hasComments?: boolean;
  author?: string;
  callForTendersCountStart?: number;
  callForTendersCountEnd?: number;
  callForTendersCount?: number;
  fundedNgosCount?: number;
  fundedNgosCountStart?: number;
  fundedNgosCountEnd?: number;
  unverifiedFundedNgosCount?: number;
  unverifiedFundedNgosCountStart?: number;
  unverifiedFundedNgosCountEnd?: number;
  unverifiedAndVerifiedFundedNgosCount?: number;
  unverifiedAndVerifiedFundedNgosCountStart?: number;
  unverifiedAndVerifiedFundedNgosCountEnd?: number;
  createdAtStart?: string;
  createdAtEnd?: string;
  updatedAtStart?: string;
  updatedAtEnd?: string;
  published?: boolean;
  department?: string[];
  region?: string[];
  sort?: SortOrder;
  modusOperandi?: string[];
  limit?: number;
};

export type LoadingStatus = 'idle' | 'pending' | 'succeeded' | 'failed';

interface DonorState {
  loading: boolean;
  saved: boolean;
  donorCount: number | null;
  loadingDonorsCount: LoadingStatus;
  event: DonorEvent | null;
  suggestionsLoading: boolean;
  error: any;
  donors: {
    byId: { [id: string]: Donor };
    allIds: string[];
  };
  duplicateDonors: Donor[][] | null;
  searchResult: {
    resultsIds: string[] | null;
    resultsCount: number;
  } | null;
  suggestionsDonors: DonorName[] | null;
  offset: number;
  classificationsFilter?: ClassificationsFilter;
}

const initialState: DonorState = {
  loading: false,
  saved: false,
  loadingDonorsCount: 'idle',
  event: null,
  suggestionsLoading: false,
  donorCount: 0,
  error: null,
  donors: {
    byId: {},
    allIds: [],
  },
  duplicateDonors: null,
  searchResult: null,
  suggestionsDonors: null,
  offset: 0,
  classificationsFilter: undefined,
};

const getParameter = (field: string, parameters: string[]) => {
  return parameters.reduce((object, item, index) => {
    return {
      ...object,
      [`${field}[${index}]`]: item,
    };
  }, {});
};

const getAllParameters = (payload: SearchParametersAdmin) => {
  return {
    ...getParameter('tags', payload.tags ?? []),
    ...getParameter('statuses', payload.statuses ?? []),
    ...getParameter('nationalities', payload.nationalities ?? []),
    ...getParameter('targetPersons', payload.targetPersons ?? []),
    ...getParameter('activityDomains', payload.activityDomains ?? []),
    ...getParameter('activityZones', payload.activityZones ?? []),
    ...getParameter('donationTypes', payload.donationTypes ?? []),
    ...getParameter('targetPopulations', payload.targetPopulations ?? []),
    ...getParameter(
      'sustainableDevelopmentGoals',
      payload.sustainableDevelopmentGoals ?? [],
    ),
    ...getParameter('department', payload.department ?? []),
    ...getParameter('region', payload.region ?? []),
    ...getParameter('modusOperandi', payload.modusOperandi ?? []),
  };
};

function normalizeDonors(list: Donor[]) {
  return list.reduce(
    (accumulator, donor: Donor) => ({
      ...accumulator,
      [donor._id]: donor,
    }),
    {},
  );
}

function nullToUndefined(value: number | undefined) {
  return value === null ? undefined : value;
}

function getDonorsIds(list: Donor[]): string[] {
  return list.map((donor) => donor._id);
}

function updateDonorsList(
  state: DonorState,
  donorsById: { [id: string]: Donor },
  donorIds: string[],
) {
  return {
    ...state.donors,
    byId: { ...state.donors.byId, ...donorsById },
    allIds: Array.from(new Set([...state.donors.allIds, ...donorIds])),
  };
}

export const fetchDonorsCount = createAsyncThunk(
  'fetchDonorsCount',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const result = await apiRequest<{ count: number }>('GET', '/donor/count');
      dispatch(donorSlice.actions.setDonorsCount(Number(result)));
      return result;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  },
);

export const removeDonorAuthor = createAsyncThunk(
  'removeDonorAuthor',
  async (payload: { _id: string; publicationStatus: string }) => {
    if (payload.publicationStatus) {
      return await apiRequest<Donor>(
        'PATCH',
        `/donor/${payload._id}`,
        undefined,
        {
          publicationStatus: payload.publicationStatus,
          author: null,
        },
      );
    }
  },
);

export const getDuplicateDonors = createAsyncThunk(
  'getDuplicateDonors',
  async () => {
    return await apiRequest<Donor[][]>(
      'GET',
      '/donor/duplicates',
      undefined,
      undefined,
      300000,
    );
  },
);

export const updateDonor = createAsyncThunk(
  'updateDonor',
  async (payload: { updateDonorDto: UpdateDonorDto }, getThunkApi) => {
    const {
      donor: { donors },
    }: any = getThunkApi.getState();
    const previousDonor: Donor = donors.byId[payload.updateDonorDto._id];
    if (!previousDonor) {
      return null;
    }
    const {
      _id,
      tags,
      places,
      activityDomains,
      sustainableDevelopmentGoals,
      activityZones,
      targetPopulations,
      fileUrls,
      callForTenders,
      ...rest
    } = payload.updateDonorDto;

    const previousArrays = {
      ...(tags && { tags: previousDonor.tags }),
      ...(places && { places: previousDonor.places }),
      ...(activityDomains && {
        activityDomains: previousDonor.activityDomains,
      }),
      ...(sustainableDevelopmentGoals && {
        sustainableDevelopmentGoals: previousDonor.sustainableDevelopmentGoals,
      }),
      ...(activityZones && { activityZones: previousDonor.activityZones }),
      ...(targetPopulations && {
        targetPopulations: previousDonor.targetPopulations,
      }),
      ...(fileUrls && { fileUrls: previousDonor.fileUrls }),
      ...(callForTenders && { callForTenders: previousDonor.callForTenders }),
    };

    return await apiRequest<Donor>('PATCH', `/donor/${_id}`, undefined, {
      ...rest,
      uniqueZipcode: rest.uniqueZipcode ? rest.uniqueZipcode : '',
      zipcode: rest.zipcode || '',
      city: rest.city || '',
      roadAndNumber: rest.roadAndNumber || '',
      ...createAddRemoveObject(previousArrays, {
        ...(tags ? { tags: tags } : {}),
        ...(places ? { places: places } : {}),
        ...(activityDomains ? { activityDomains: activityDomains } : {}),
        ...(sustainableDevelopmentGoals
          ? { sustainableDevelopmentGoals: sustainableDevelopmentGoals }
          : {}),
        ...(activityZones ? { activityZones: activityZones } : {}),
        ...(targetPopulations ? { targetPopulations: targetPopulations } : {}),
        ...(fileUrls ? { fileUrls: fileUrls } : {}),
        ...(callForTenders ? { callForTenders: callForTenders } : {}),
      }),
    });
  },
);

export const createDonor = createAsyncThunk(
  'createDonor',
  async (payload: Donor) => {
    payload['activityDomains'] = payload['activityDomains'] || [];
    payload['tags'] = payload['tags'] || [];
    payload['places'] = payload['places'] || [];
    payload['uniqueZipcode'] = payload['uniqueZipcode'] || '';
    payload['city'] = payload['city'] || '';
    payload['roadAndNumber'] = payload['roadAndNumber'] || '';
    payload['zipcode'] = payload['zipcode'] || '';
    payload['targetPopulations'] = payload['targetPopulations'] || [];
    payload['activityZones'] = payload['activityZones'] || [];
    payload['status'] = payload['status'] || '';
    payload['targetPerson'] = payload['targetPerson'] || [];
    payload['sustainableDevelopmentGoals'] =
      payload['sustainableDevelopmentGoals'] || [];
    return await apiRequest<Donor>('POST', `/donor`, undefined, payload);
  },
);

export const updateDonorAuthor = createAsyncThunk(
  'updateDonorAuthor',
  async (payload: { donorsIds: string[]; authorId: string }) => {
    const { donorsIds, authorId } = payload;
    return await apiRequest<Donor[]>('POST', `/donor/updateAuthor`, undefined, {
      donorsIds: donorsIds,
      authorId: authorId,
    });
  },
);

export const updateDonorPublicationStatus = createAsyncThunk(
  'updateDonorPublicationStatus',
  async (payload: { donorsIds: string[]; publicationStatus: string }) => {
    const { donorsIds, publicationStatus } = payload;
    return await apiRequest<Donor[]>(
      'POST',
      `/donor/updatePublicationStatus`,
      undefined,
      {
        donorsIds: donorsIds,
        publicationStatus: publicationStatus,
      },
    );
  },
);

export const updateDonorPublished = createAsyncThunk(
  'updateDonorPublished',
  async (payload: { donorsIds: string[]; published: boolean }) => {
    const { donorsIds, published } = payload;
    return await apiRequest<Donor[]>(
      'POST',
      `/donor/updatePublished`,
      undefined,
      {
        donorsIds: donorsIds,
        published: published,
      },
    );
  },
);

export const searchDonors = createAsyncThunk(
  'searchDonors',
  async (payload: SearchParameters) => {
    const params: any = {
      name: payload.name,
      limit: DONORS_MAX_RESULTS,
      offset: payload.offset,
      ...getAllParameters(payload),
    };
    return {
      ...(await apiRequest<SearchResults>('GET', '/donor/search', params)),
      offset: payload.offset,
    };
  },
);

export const searchDonorsByFinancingNeed = createAsyncThunk(
  'searchDonorsByFinancingNeed',
  async ({ id }: { id: string }) => {
    const params: any = {
      limit: 1, // TODO: remove this limit as there is no limit in the backend
      offset: 0, // TODO: remove this offset as there is no pagination
    };
    return {
      ...(await apiRequest<SearchResults>(
        'GET',
        `/donor/search-by-financing-need/${id}`,
        params,
      )),
    };
  },
);

export const searchAllDonors = createAsyncThunk(
  'searchAllDonors',
  async (payload: SearchParametersAdmin) => {
    const params: any = {
      name: payload.name,
      limit: DONORS_MAX_RESULTS,
      offset: payload.offset,
      published: payload.published,
      ...getAllParameters(payload),
      source: payload.source,
      nationality: payload.nationality,
      publicationStatus: payload.publicationStatus,
      hasLogo: payload.hasLogo,
      hasHomepageUrl: payload.hasHomepageUrl,
      worksInIsolation: payload.worksInIsolation,
      hasLinkedinUrl: payload.hasLinkedinUrl,
      hasCompanyLinkedinUrl: payload.hasCompanyLinkedinUrl,
      isHostedBy: payload.isHostedBy,
      hasDescription: payload.hasDescription,
      usesCallsToTender: payload.usesCallsToTender,
      hasComments: payload.hasComments,
      author: payload.author,
      callForTendersCountStart: nullToUndefined(
        payload.callForTendersCountStart,
      ),
      callForTendersCountEnd: nullToUndefined(payload.callForTendersCountEnd),
      callForTendersCount: nullToUndefined(payload.callForTendersCount),
      fundedNgosCount: nullToUndefined(payload.fundedNgosCount),
      fundedNgosCountStart: nullToUndefined(payload.fundedNgosCountStart),
      fundedNgosCountEnd: nullToUndefined(payload.fundedNgosCountEnd),
      unverifiedFundedNgosCount: nullToUndefined(
        payload.unverifiedFundedNgosCount,
      ),
      unverifiedFundedNgosCountStart: nullToUndefined(
        payload.unverifiedFundedNgosCountStart,
      ),
      unverifiedFundedNgosCountEnd: nullToUndefined(
        payload.unverifiedFundedNgosCountEnd,
      ),
      unverifiedAndVerifiedFundedNgosCount: nullToUndefined(
        payload.unverifiedAndVerifiedFundedNgosCount,
      ),
      unverifiedAndVerifiedFundedNgosCountStart: nullToUndefined(
        payload.unverifiedAndVerifiedFundedNgosCountStart,
      ),
      unverifiedAndVerifiedFundedNgosCountEnd: nullToUndefined(
        payload.unverifiedAndVerifiedFundedNgosCountEnd,
      ),
      sort: payload.sort,
      createdAtStart: payload.createdAtStart,
      createdAtEnd: payload.createdAtEnd,
      updatedAtStart: payload.updatedAtStart,
      updatedAtEnd: payload.updatedAtEnd,
    };
    return {
      ...(await apiRequest<SearchResults>('GET', '/donor/searchAll', params)),
      offset: payload.offset,
    };
  },
);

export const searchDonorsWithAlgorithmAndGetSuggestions = createAsyncThunk(
  'searchDonorsWithAlgorithmAndGetSuggestions',
  async (payload: {
    projectId: string;
    algorithm: string;
    searchParameters: SearchParameters;
  }) => {
    const params = {
      limit: DONORS_MAX_RESULTS,
      name: payload.searchParameters.name || '',
      ...getAllParameters(payload.searchParameters),
    };
    return await apiRequest<DonorName[]>(
      'GET',
      `/financing-need/${payload.projectId}/search-with-algorithm/${payload.algorithm}/suggestions`,
      params,
    );
  },
);

export const getDonorsWithAlgorithm = createAsyncThunk(
  'getDonorsWithAlgorithm',
  async (payload: {
    projectId: string;
    algorithm: string;
    searchParameters: SearchParameters;
  }) => {
    const params = {
      limit: payload.searchParameters.limit ?? DONORS_MAX_RESULTS,
      offset: payload.searchParameters.offset,
      name: payload.searchParameters.name || '',
      ...getAllParameters(payload.searchParameters),
    };
    console.log(params);
    console.log('searchParameters', payload.searchParameters);

    return {
      ...(await apiRequest<SearchResults>(
        'GET',
        `/financing-need/${payload.projectId}/search-with-algorithm/${payload.algorithm}`,
        params,
      )),
      offset: payload.searchParameters.offset,
    };
  },
);

export const saveContributorDonor = createAsyncThunk(
  'saveDonor',
  async (payload: SaveContributorDonorDto, { dispatch }) => {
    const body = { ...payload, published: false };
    const result = await apiRequest<Donor>(
      'POST',
      '/donor/simple',
      undefined,
      body,
    );
    if (result) {
      dispatch(getDonor(result._id));
    }
    return result;
  },
);

export const getDonorsForContributor = createAsyncThunk(
  'getDonorsForContributor',
  async (
    payload: {
      limit: number;
      statusFilter: string;
      hasAuthor?: boolean;
    } & SearchParameters,
  ) => {
    const params: any = {
      name: payload.name,
      limit: DONORS_MAX_RESULTS,
      offset: payload.offset,
      statusFilter: payload.statusFilter,
      hasAuthor: payload.hasAuthor ? 'true' : 'false',
      source: payload.source,
      ...getAllParameters(payload),
    };

    return {
      ...(await apiRequest<SearchResults>(
        'GET',
        '/donor/getForContributor',
        params,
      )),
      offset: payload.offset,
    };
  },
);
export const publishDonors = createAsyncThunk(
  'publishDonors',
  async (payload: { _id: string }) => {
    const id = payload._id;
    await apiRequest<Donor>('POST', `/donor/${id}/publish`, payload);
    return payload;
  },
);

export const searchDonorsAndGetSuggestions = createAsyncThunk(
  'searchDonorsAndGetSuggestions',
  async (payload: SearchParameters) => {
    try {
      const params: any = {
        name: payload.name,
        limit: DONORS_MAX_RESULTS,
        ...getAllParameters(payload),
      };
      return await apiRequest<DonorName[]>('GET', '/donor/suggestions', params);
    } catch (error) {
      return null;
    }
  },
);

export const searchDonorsByFinancingNeedAndGetSuggestions = createAsyncThunk(
  'searchDonorsByFinancingNeedAndGetSuggestions',
  async (payload: SearchParameters) => {
    try {
      const params: any = {
        name: payload.name,
        limit: DONORS_MAX_RESULTS,
        ...getAllParameters(payload),
      };
      return await apiRequest<DonorName[]>(
        'GET',
        '/donor/favoriteSuggestions',
        params,
      );
    } catch (error) {
      return null;
    }
  },
);

export const getDonorsFromNgoId = createAsyncThunk(
  'getDonorsFromNgoId',
  async (ngoId: string) => {
    const results = await apiRequest<DonorName[]>(
      'GET',
      `/ngo/${ngoId}/donors`,
    );

    return { items: results, offset: 0, totalItems: results.length };
  },
);

export const useMerge = createAsyncThunk(
  'useMerge',
  async (
    { index, selectedId }: { index: number; selectedId: string },
    { getState },
  ) => {
    const {
      donor: { duplicateDonors },
    }: any = getState();

    await apiRequest('POST', '/donor/useMerge', undefined, {
      selectedId,
      mergeIds: duplicateDonors[index].map((donor: Donor) => donor._id),
    });

    return index;
  },
);

export const ignoreDuplicate = createAsyncThunk(
  'ignoreDuplicate',
  async ({ index, ignoreId }: { index: number; ignoreId: string }) => {
    await apiRequest('PATCH', `/donor/${ignoreId}`, undefined, {
      ignoreDuplicate: true,
    });

    return { index, ignoreId };
  },
);

export const merge = createAsyncThunk(
  'merge',
  async ({
    index,
    id,
    payload,
  }: {
    index: number;
    id: string;
    payload: any;
  }) => {
    await apiRequest('POST', `/donor/${id}/merge`, undefined, payload);

    return index;
  },
);

export const reportDonorProblem = createAsyncThunk(
  'ReportDonorProblem',
  async (payload: {
    _id: string;
    name: string;
    problemDescription: string;
  }) => {
    await apiRequest(
      'POST',
      `/donor/${payload._id}/problem`,
      undefined,
      payload,
    );
    return payload;
  },
);

/* Shared Reducers */

const pendingReducer = (state: DonorState) => {
  state.loading = true;
  state.error = null;
};

const fulfilledReducer = (state: DonorState) => {
  state.loading = false;
};

const rejectedReducer = (
  state: DonorState,
  { error }: { error: SerializedError },
) => {
  state.loading = false;
  state.error = error.message || 'error';
};

const receiveDonorsReducer = (
  state: DonorState,
  { payload }: { payload: any },
) => {
  state.loading = false;
  if (payload) {
    const donorsIds = getDonorsIds(payload.items);
    const donorsById = normalizeDonors(payload.items);
    state.donors = {
      allIds: donorsIds,
      byId: donorsById,
    };
    state.offset = payload.offset;
    state.searchResult = {
      resultsCount: payload.totalItems,
      resultsIds: donorsIds,
    };

    state.classificationsFilter = payload.classificationsFilter;
  }
};

const receiveSuggestionsReducer = (
  state: DonorState,
  { payload }: { payload: any },
) => {
  state.suggestionsLoading = false;
  state.loading = false;
  if (payload) {
    state.suggestionsDonors = payload;
  }
};

/* Slice */

export const getDonor = createAsyncThunk(
  'getDonor',
  async (donorId: string | undefined) => {
    return await apiRequest<Donor>('GET', `/donor/${donorId}`);
  },
);

export const deleteDonor = createAsyncThunk(
  'deleteDonor',
  async (donorId: string) => {
    return await apiRequest<{ status: boolean }>('DELETE', `/donor/${donorId}`);
  },
);

const donorSlice = createSlice({
  name: 'donor',
  reducers: {
    resetSuggestions(state: DonorState) {
      state.suggestionsDonors = null;
    },
    resetDonorEvent(state: DonorState) {
      state.event = null;
    },
    setDonorsCount: (state, action: PayloadAction<number>) => {
      state.donorCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateDonor.pending, pendingReducer)
      .addCase(updateDonor.rejected, rejectedReducer)
      .addCase(updateDonor.fulfilled, (state: DonorState, { payload }) => {
        state.loading = false;
        if (payload) {
          state.donors.byId[payload._id] = payload;
        }
      })
      .addCase(updateDonorAuthor.pending, (state: DonorState) => {
        state.loading = false;
        state.saved = false;
      })
      .addCase(updateDonorAuthor.rejected, (state: DonorState, { payload }) => {
        state.loading = false;
        state.saved = false;
        state.error = payload;
      })
      .addCase(
        updateDonorAuthor.fulfilled,
        (state: DonorState, { payload }) => {
          state.loading = false;
          state.saved = true;
          if (payload) {
            payload.map((donor) => (state.donors.byId[donor._id] = donor));
          }
        },
      )
      .addCase(updateDonorPublicationStatus.pending, (state: DonorState) => {
        state.loading = false;
        state.saved = false;
      })
      .addCase(
        updateDonorPublicationStatus.rejected,
        (state: DonorState, { payload }) => {
          state.loading = false;
          state.saved = false;
          state.error = payload;
        },
      )
      .addCase(
        updateDonorPublicationStatus.fulfilled,
        (state: DonorState, { payload }) => {
          state.loading = false;
          state.saved = true;
          if (payload) {
            payload.map((donor) => (state.donors.byId[donor._id] = donor));
          }
        },
      )
      .addCase(updateDonorPublished.pending, (state: DonorState) => {
        state.loading = false;
        state.saved = false;
      })
      .addCase(
        updateDonorPublished.rejected,
        (state: DonorState, { payload }) => {
          state.loading = false;
          state.saved = false;
          state.error = payload;
        },
      )
      .addCase(
        updateDonorPublished.fulfilled,
        (state: DonorState, { payload }) => {
          state.loading = false;
          state.saved = true;
          if (payload) {
            payload.map((donor) => (state.donors.byId[donor._id] = donor));
          }
        },
      )
      .addCase(createDonor.pending, (state: DonorState) => {
        state.loading = false;
        state.saved = false;
      })
      .addCase(createDonor.rejected, (state: DonorState, { payload }) => {
        state.loading = false;
        state.saved = false;
        state.error = payload;
      })
      .addCase(createDonor.fulfilled, (state: DonorState, { payload }) => {
        state.loading = false;
        state.saved = true;
        if (payload) {
          state.donors.allIds.push(payload._id);
          Object.assign(state.donors.byId, { [payload._id]: payload });
        }
      })
      .addCase(publishDonors.pending, pendingReducer)
      .addCase(publishDonors.rejected, rejectedReducer)
      .addCase(publishDonors.fulfilled, (state: DonorState, { payload }) => {
        state.loading = false;
        if (payload) {
          state.donors.byId[payload._id].published = true; //TODO: change with correct value
        }
      })
      .addCase(
        removeDonorAuthor.fulfilled,
        (state: DonorState, { payload }) => {
          state.loading = false;
          if (payload) {
            state.donors.byId[payload._id] = payload;
          }
        },
      )
      .addCase(searchDonors.pending, pendingReducer)
      .addCase(searchDonors.fulfilled, receiveDonorsReducer)
      .addCase(searchAllDonors.pending, pendingReducer)
      .addCase(searchAllDonors.fulfilled, receiveDonorsReducer)
      .addCase(getDonorsWithAlgorithm.pending, pendingReducer)
      .addCase(getDonorsWithAlgorithm.fulfilled, receiveDonorsReducer)
      .addCase(getDonorsWithAlgorithm.rejected, rejectedReducer)
      .addCase(
        searchDonorsWithAlgorithmAndGetSuggestions.rejected,
        rejectedReducer,
      )
      .addCase(
        searchDonorsWithAlgorithmAndGetSuggestions.pending,
        (state: DonorState) => {
          state.suggestionsLoading = true;
        },
      )
      .addCase(
        searchDonorsWithAlgorithmAndGetSuggestions.fulfilled,
        receiveSuggestionsReducer,
      )
      .addCase(getDonorsForContributor.pending, pendingReducer)
      .addCase(getDonorsForContributor.fulfilled, receiveDonorsReducer)
      .addCase(getDonorsForContributor.rejected, rejectedReducer)
      .addCase(getDonorsFromNgoId.pending, pendingReducer)
      .addCase(getDonorsFromNgoId.fulfilled, receiveDonorsReducer)
      .addCase(getDonorsFromNgoId.rejected, rejectedReducer)
      .addCase(searchDonorsAndGetSuggestions.rejected, rejectedReducer)
      .addCase(searchDonorsAndGetSuggestions.pending, (state: DonorState) => {
        state.suggestionsLoading = true;
      })
      .addCase(
        searchDonorsAndGetSuggestions.fulfilled,
        receiveSuggestionsReducer,
      )
      .addCase(
        searchDonorsByFinancingNeedAndGetSuggestions.rejected,
        rejectedReducer,
      )
      .addCase(
        searchDonorsByFinancingNeedAndGetSuggestions.pending,
        (state: DonorState) => {
          state.suggestionsLoading = true;
        },
      )
      .addCase(
        searchDonorsByFinancingNeedAndGetSuggestions.fulfilled,
        receiveSuggestionsReducer,
      )
      .addCase(searchDonors.rejected, rejectedReducer)
      .addCase(getDuplicateDonors.pending, pendingReducer)
      .addCase(getDuplicateDonors.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.duplicateDonors = payload;
      })
      .addCase(getDuplicateDonors.rejected, rejectedReducer)
      .addCase(getDonor.pending, pendingReducer)
      .addCase(getDonor.fulfilled, (state: DonorState, { payload }) => {
        state.loading = false;
        const donorById = { [payload._id]: payload };
        state.donors = updateDonorsList(state, donorById, [payload._id]);
      })
      .addCase(getDonor.rejected, rejectedReducer)
      .addCase(useMerge.pending, pendingReducer)
      .addCase(useMerge.fulfilled, (state: DonorState, { payload }) => {
        state.duplicateDonors?.splice(payload, 1);
        state.loading = false;
      })
      .addCase(useMerge.rejected, rejectedReducer)
      .addCase(ignoreDuplicate.pending, pendingReducer)
      .addCase(ignoreDuplicate.fulfilled, (state: DonorState, { payload }) => {
        if (state.duplicateDonors) {
          if (state.duplicateDonors[payload.index].length <= 2) {
            state.duplicateDonors.splice(payload.index, 1);
          } else {
            const index = state.duplicateDonors[payload.index].findIndex(
              (donor) => donor._id === payload.ignoreId,
            );

            if (index > -1) {
              state.duplicateDonors[payload.index].splice(index, 1);
            }
          }
        }
        state.loading = false;
      })
      .addCase(ignoreDuplicate.rejected, rejectedReducer)
      .addCase(merge.pending, pendingReducer)
      .addCase(merge.fulfilled, (state: DonorState, { payload }) => {
        state.duplicateDonors?.splice(payload, 1);
        state.loading = false;
      })
      .addCase(merge.rejected, rejectedReducer)
      .addCase(reportDonorProblem.pending, pendingReducer)
      .addCase(reportDonorProblem.rejected, rejectedReducer)
      .addCase(reportDonorProblem.fulfilled, (state: DonorState) => {
        state.loading = false;
      })
      .addCase(searchDonorsByFinancingNeed.pending, pendingReducer)
      .addCase(searchDonorsByFinancingNeed.fulfilled, receiveDonorsReducer)
      .addCase(searchDonorsByFinancingNeed.rejected, rejectedReducer)
      .addCase(saveContributorDonor.pending, (state: DonorState) => {
        state.loading = true;
        state.saved = false;
      })
      .addCase(saveContributorDonor.fulfilled, (state: DonorState, {}) => {
        state.saved = true;
        state.loading = false;
      })
      .addCase(saveContributorDonor.rejected, (state: DonorState, {}) => {
        state.saved = false;
        state.loading = false;
      })
      .addCase(deleteDonor.pending, (state: DonorState) => {
        state.loading = true;
      })
      .addCase(deleteDonor.fulfilled, (state: DonorState) => {
        state.loading = false;
      })
      .addCase(deleteDonor.rejected, (state: DonorState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(fetchDonorsCount.pending, pendingReducer)
      .addCase(fetchDonorsCount.rejected, rejectedReducer)
      .addCase(fetchDonorsCount.fulfilled, fulfilledReducer);
  },
  initialState,
});

export const { resetSuggestions, resetDonorEvent } = donorSlice.actions;

export default donorSlice.reducer;
